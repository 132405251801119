import { Button, Checkbox, FormControlLabel, IconButton, InputAdornment, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useContext, useEffect, useState } from 'react'
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import '../Prototypes';
import useApi from '../hooks/useApi';
import MainContext from '../contexts/MainContext'
import Search from './Search';

const Countries = () => {
  
  const {countries, getCountries} = useContext(MainContext);
  const api = useApi();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [country, setCountry] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    setFilteredItems(countries.filter(item => (search === '' || item.name.toLowerCase().includes(search.toLowerCase()))));
    setPage(0);
  }, [countries, search])

  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const add = () => {
    setCountry({
      name: '',
      alpha2: '',
      alpha3: '',
      eu: false,
      base: false,
      vat: '',
      locked: false
    })
  }

  const edit = (item) => {
    setCountry(item);
  }

  const save = async() => {
    setIsProcessing(true);
    const data = country.id ? await api.updateCountry(country) : await api.addCountry(country);
    if(data) {
      setCountry(null);
      getCountries();
    }
    setIsProcessing(false);
  }
  
  const remove = async() => {
    setIsProcessing(true);
    const data = await api.removeCountry(country.id);
    if(data) {
      setCountry(null);
      getCountries();
    }
    setIsProcessing(false);
  }
  
  const abort = () => {
    setCountry(null);
  }
  
  return (
    <>
      <Paper className="spacing-2" sx={{height:'100%'}}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Typography variant="h2">Länder</Typography>
              <Button variant="outlined" onClick={add}>Hinzufügen</Button>
          </Stack>
          <Search search={search} setSearch={setSearch} />
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><Typography variant="caption">Name</Typography></TableCell>
                  <TableCell><Typography variant="caption">Alpha2</Typography></TableCell>
                  <TableCell><Typography variant="caption">Alpha3</Typography></TableCell>
                  <TableCell><Typography variant="caption">EU</Typography></TableCell>
                  <TableCell><Typography variant="caption">Basis</Typography></TableCell>
                  <TableCell><Typography variant="caption">Gesperrt</Typography></TableCell>
                  <TableCell><Typography variant="caption">Umsatzsteuer</Typography></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.alpha2}</TableCell>
                  <TableCell>{item.alpha3}</TableCell>
                  <TableCell>{item.eu ? <CheckBoxOutlinedIcon fontSize="small" /> : <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />}</TableCell>
                  <TableCell>{item.base ? <CheckBoxOutlinedIcon fontSize="small" /> : <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />}</TableCell>
                  <TableCell>{item.locked ? <CheckBoxOutlinedIcon fontSize="small" /> : <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />}</TableCell>
                  <TableCell>{item.vat.toCommaString()}</TableCell>
                  <TableCell sx={{textAlign:'right'}}>
                    <IconButton onClick={() => edit(item)}>
                      <EditOutlinedIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                  <TableRow>
                    <TablePagination 
                      count={filteredItems.length} 
                      page={page} 
                      rowsPerPage={rowsPerPage} 
                      rowsPerPageOptions={[]}
                      onPageChange={onPageChange}
                    />
                  </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Stack>
      </Paper>
      <Modal open={country !== null} onClose={abort}>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{minHeight:'100%'}}>
          {country !== null ? (
          <Paper className="spacing-2">
            <Stack spacing={2}>
              <Typography variant="h2">Land</Typography>
              <TextField
                size="small"
                label="Name"
                value={country.name}
                onChange={(e) => setCountry({...country, name:e.target.value})}
              />
              <TextField
                size="small"
                label="Name (EN)"
                value={country.name_en}
                onChange={(e) => setCountry({...country, name_en:e.target.value})}
              />
              <Stack direction="row" spacing={2}>
                <TextField
                  size="small"
                  label="Alpha2"
                  value={country.alpha2}
                  onChange={(e) => setCountry({...country, alpha2:e.target.value})}
                />
                <TextField
                  size="small"
                  label="Alpha3"
                  value={country.alpha3}
                  onChange={(e) => setCountry({...country, alpha3:e.target.value})}
                />
              </Stack>
              <TextField
                  size="small"
                  label="Umsatzsteuer"
                  InputProps={{
                    endAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}
                  value={country.vat.toCommaString()}
                  onChange={(e) => setCountry({...country, vat:e.target.value.toFloatString()})}
              />
              <Stack direction="row" spacing={2}>
                <FormControlLabel control={<Checkbox checked={country.eu} onChange={(e) => setCountry({...country, eu:e.target.checked})} />} label="EU" />
                <FormControlLabel control={<Checkbox checked={country.base} onChange={(e) => setCountry({...country, base:e.target.checked})} />} label="Basis" />
                <FormControlLabel control={<Checkbox checked={country.locked} onChange={(e) => setCountry({...country, locked:e.target.checked})} />} label="Gesperrt" />
              </Stack>
              <Stack direction="row" spacing={2}>
                {country.id ? (
                <Button variant="outlined" color="error" onClick={remove} disabled={isProcessing}>Löschen</Button>
                ) : null}
                <Button variant="outlined" onClick={abort}>Abbrechen</Button>
                <Button variant="contained" onClick={save} disabled={isProcessing}>Speichern</Button>
              </Stack>

            </Stack>
          </Paper>
          ): null}
        </Stack>
      </Modal>
    </>
  )
}

export default Countries
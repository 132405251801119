import React, { useContext, useEffect } from 'react'
import Calculator from '../components/Calculator'
import TopBar from '../components/TopBar'
import Page from '../layout/Page'
import PageBody from '../layout/PageBody'
import { Box } from '@mui/material'

const CalculatorPage = () => {
  
  return (
  
    <Page>

      <TopBar label="Kalkulator"></TopBar>

      <Box sx={{flexGrow:1, position:'relative'}}>
        <Calculator />
      </Box>
      
    </Page>
  )
}

export default CalculatorPage
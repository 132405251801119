import { Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import '../Prototypes';
import useApi from '../hooks/useApi';

const SeasonSurcharges = ({freightTypeId}) => {
  
    const api = useApi();
    const [seasonSurcharges, setSeasonSurcharges] = useState([]);
    const [seasonSurcharge, setSeasonSurcharge] = useState(null);
    const [isProcessing, setIsProcessing] =  useState(false);
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    useEffect(() => {
        getSeasonSurcharges();
    }, []);
  
    const getSeasonSurcharges = async() => {
        const data = await api.getSeasonSurchargesByFreightTypeId(freightTypeId);
        if(data) {
            setSeasonSurcharges(data.results);
        }
    }

    const add = () => {
        setSeasonSurcharge({
            freight_type_id: freightTypeId,
            month: '',
            surcharge: ''
        });
    }

    const edit = (item) => {
        setSeasonSurcharge({
            id: item.id,
            _month: item.month,
            surcharge: item.surcharge
        });
    }

    const save = async() => {
        setIsProcessing(true);
        const data = seasonSurcharge.id ? await api.updateSeasonSurcharge(seasonSurcharge) : await api.addSeasonSurcharge(seasonSurcharge);
        if(data) {
            setSeasonSurcharge(null);
            getSeasonSurcharges();
        }
        setIsProcessing(false);
    }

    const remove = async() => {
        setIsProcessing(true);
        const data = await api.removeSeasonSurcharge(seasonSurcharge.id)
        if(data) {
            setSeasonSurcharge(null);
            getSeasonSurcharges();
        }
        setIsProcessing(false);
    }

    const abort = () => {
        setSeasonSurcharge(null)
    }

return (
    <>
        <Paper className="spacing-2" sx={{height:'100%'}}>
            <Stack spacing={2}>
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                    <Typography variant="h2">Saisonzuschläge</Typography>
                    <Button variant="outlined" onClick={add}>Hinzufügen</Button>
                </Stack>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow sx={{verticalAlign:'top'}}>
                                <TableCell><Typography variant="caption">Monat</Typography></TableCell>
                                <TableCell><Typography variant="caption">Zuschlag</Typography></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {seasonSurcharges.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.month}</TableCell>
                                <TableCell>{item.surcharge.toLocaleString()}</TableCell>
                                <TableCell sx={{textAlign:'right'}}>
                                    <IconButton onClick={() => edit(item)}>
                                        <EditOutlinedIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </Paper>
        <Modal open={seasonSurcharge !== null} onClose={abort}>
            <Stack direction="row" alignItems="center" justifyContent="center" sx={{minHeight:'100%'}}>
            {seasonSurcharge !== null ? (
            <Paper className="spacing-2">
                <Stack spacing={2}>
                    <Typography variant="h2">Saison Faktor</Typography>
                    <Stack direction="row" spacing={2}>
                        <FormControl fullWidth>
                            <InputLabel size="small" id="select-label">Monat</InputLabel>
                            <Select size="small"
                                fullWidth
                                labelId="select-label"
                                value={seasonSurcharge.id ? seasonSurcharge._month : seasonSurcharge.month}
                                onChange={(e) => setSeasonSurcharge({...seasonSurcharge, month: e.target.value})}
                                disabled={seasonSurcharge.id ? true : false}
                                label="Monat"
                                >
                                {months.map((month) => (
                                    <MenuItem key={month} value={month}>{month}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            size="small" fullWidth
                            label="Zuschlag"
                            value={seasonSurcharge.surcharge.toString().toCommaString()}
                            onChange={(e) => setSeasonSurcharge({...seasonSurcharge, surcharge: e.target.value.toFloatString()})}
                        />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        {seasonSurcharge.id ? (
                        <Button variant="outlined" color="error" onClick={remove} disabled={isProcessing}>Löschen</Button>
                        ) : null}
                        <Button variant="outlined" onClick={abort}>Abbrechen</Button>
                        <Button variant="contained" onClick={save} disabled={isProcessing || ((!seasonSurcharge.month || !seasonSurcharge.surcharge) && (!seasonSurcharge.id || !seasonSurcharge.surcharge))}>Speichern</Button>
                    </Stack>
                </Stack>
            </Paper>
            ) : null}
            </Stack>
        </Modal>
    </>

  )
}

export default SeasonSurcharges
import React, { useEffect, useState } from 'react'
import Enquiries from '../components/Enquiries'
import Page from '../layout/Page'
import TopBar from '../components/TopBar'
import { Box } from '@mui/material'
import Enquiry from '../components/Enquiry'
import useApi from '../hooks/useApi'
import { useDebounce } from '../hooks/useDebounce'

const EnquiriesPage = () => {
  
  const filterData = {
    search:'',
    period:7,
    freight_type_id: '',
    status_id: '',
    country_id: '',
    zipcode: '',
    has_company: ''
  }

  const api = useApi();
  const [enquiry, setEnquiry] = useState(null)
  const [enquiries, setEnquiries] = useState([]);
  const [filter, setFilter] = useState(filterData);
  const debouncedFilter = useDebounce(filter, 500);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    getEnquiries();
  }, [debouncedFilter])

  const getEnquiries = async() => {
    setIsProcessing(true);
    const data = await api.getEnquiries(filter);
    if(data) {
        setEnquiries(data.results);
    }
    setIsProcessing(false);
  }

  const getEnquiry = async(id) => {
    const data = await api.getEnquiry(id);
    if(data) {
        setEnquiry(data.result);
    }
  }

  const resetFilter = () => {
    setFilter(filterData);
  }

  return (
    <Page>
      <TopBar label="Anfragen"></TopBar>
      <Box sx={{flexGrow:1, position:'relative'}}>
        <Enquiries enquiries={enquiries} filter={filter} setFilter={setFilter} resetFilter={resetFilter} getEnquiry={getEnquiry} getEnquiries={getEnquiries} isProcessing={isProcessing} show={enquiry === null} />
        {enquiry !== null ? (
        <Enquiry enquiry={enquiry} setEnquiry={setEnquiry} getEnquiries={getEnquiries} />
        ) : null}
      </Box>
    </Page>
  )
}

export default EnquiriesPage
import React, { useEffect, useState } from 'react'
import useApi from '../hooks/useApi';
import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Modal, OutlinedInput, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const Articles = () => {
  
  const api = useApi();
  const [articleGroups, setArticleGroups] = useState([]);
  const [article, setArticle] = useState(null);
  const [articles, setArticles] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  
  useEffect(() => {
    getArticleGroups();
    getArticles();
  }, []);
  
  const getArticleGroups = async() => {
    const data = await api.getArticleGroups();
    if(data) {
      setArticleGroups(data.results);
    }
  }

  const getArticles = async() => {
    const data = await api.getArticles();
    if(data) {
      setArticles(data.results);
    }
  }

  const add = () => {
    setArticle({
      article_group_id: '',
      name: '',
      name_en: '',
      volume_share: '',
      rate: '',
      sort: '',
      essential: false,
      excluded_articles: []
    });
  }

  const edit = (item) => {
    setArticle({
      id: item.id,
      article_group_id: item.article_group_id,
      name: item.name,
      name_en: item.name_en,
      volume_share: item.volume_share ? item.volume_share : '',
      rate: item.rate ? item.rate : '',
      sort: item.sort,
      essential: item.essential,
      excluded_articles: item.exclusions.map((ex) => articles.find(s => s.id === ex.article_exclude_id))
    });
  }

  const save = async() => {
    setIsProcessing(true);
    const data = article.id ? await api.updateArticle(article) : await api.addArticle(article);
    if(data) {
      setArticle(null);
      getArticles();
    }
    setIsProcessing(false);
  }

  const remove = async() => {
    setIsProcessing(true);
    const data = await api.removeArticle(article.id);
    if(data.success) {
      setArticle(null);
      getArticles();
    }
    setIsProcessing(false);
  }

  const abort = () => {
    setArticle(null);
  }


  return (
    <>
      <Paper className="spacing-2" sx={{height:'100%'}}>
        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
          <Typography variant="h2">Artikel</Typography>
          <Button variant="outlined" onClick={add}>Hinzufügen</Button>
        </Stack>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant="caption">ID</Typography></TableCell>
                <TableCell><Typography variant="caption">Name</Typography></TableCell>
                <TableCell><Typography variant="caption">Volumenanteil<br/>%</Typography></TableCell>
                <TableCell><Typography variant="caption">Kosten<br/>EUR</Typography></TableCell>
                <TableCell><Typography variant="caption">Reihenfolge</Typography></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {articles.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.volume_share ? item.volume_share.toCommaString() : ''}</TableCell>
                <TableCell>{item.rate ? item.rate.toCommaString() : ''}</TableCell>
                <TableCell>{item.sort}</TableCell>
                <TableCell sx={{textAlign:'right'}}>
                  <IconButton onClick={() => edit(item)}>
                    <EditOutlinedIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Modal open={article !== null} onClose={abort}>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{minHeight:'100%'}}>
          {article !== null ? (
          <Paper className="spacing-2">
            <Stack spacing={2}>
              <Typography variant="h2">Artikel {article.id}</Typography>
              <TextField
                size="small"
                label="Reihenfolge"
                value={article.sort}
                onChange={(e) => setArticle({...article, sort:e.target.value})}
              />
              <TextField multiline
                size="small"
                label="Name"
                value={article.name}
                onChange={(e) => setArticle({...article, name:e.target.value})}
              />
              <TextField multiline
                size="small"
                label="Name (EN)"
                value={article.name_en}
                onChange={(e) => setArticle({...article, name_en:e.target.value})}
              />
              <FormControl fullWidth>
                  <InputLabel size="small">Artikelgruppe</InputLabel>
                  <Select size="small"
                      fullWidth
                      value={article.article_group_id}
                      onChange={(e) => setArticle({...article, article_group_id: e.target.value})}
                      label="Artikelgruppe"
                      >
                      {articleGroups.map((item) => (
                          <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                      ))}
                  </Select>
              </FormControl>
              <TextField
                size="small"
                label="Volumenanteil"
                value={article.volume_share.toCommaString()}
                onChange={(e) => setArticle({...article, volume_share:e.target.value.toFloatString()})}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
              <TextField
                size="small"
                label="Kosten"
                value={article.rate.toCommaString()}
                onChange={(e) => setArticle({...article, rate:e.target.value.toFloatString()})}
                InputProps={{
                  endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
                }}
              />
              <FormControl>
                <InputLabel>Ausgeschlossene Artikel</InputLabel>
                <Select sx={{width: '600px'}}
                  multiple
                  value={article.excluded_articles}
                  onChange={(e) => setArticle({...article, excluded_articles:e.target.value})}
                  input={<OutlinedInput label="Ausgeschlossene Artikel" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((s) => (
                        <Chip key={s.id} label={s.name} />
                      ))}
                    </Box>
                  )}
                >
                  {articles.map((s) => (
                    <MenuItem key={s.id} value={s}>{s.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControlLabel control={<Checkbox checked={article.essential} onChange={(e) => setArticle({...article, essential:e.target.checked})} />} label="Essentiell" disabled={article.essential} />

              <Stack direction="row" spacing={2}>
                {article.id ? (
                <Button variant="outlined" color="error" onClick={remove} disabled={isProcessing || article.essential}>Löschen</Button>
                ) : null}
                <Button variant="outlined" onClick={abort}>Abbrechen</Button>
                <Button variant="contained" onClick={save} disabled={isProcessing}>Speichern</Button>
              </Stack>
            </Stack>
          </Paper>
          ) : null }
        </Stack>
      </Modal>
    </>
  )
}

export default Articles
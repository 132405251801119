import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import useApi from '../hooks/useApi';
import InsuranceClasses from '../components/InsuranceClasses';
import CustomsClasses from '../components/CustomsClasses';
import TransportParameter from '../components/TransportParameter';
import TransportCountryParameters from '../components/TransportCountryParameters';

const TransportParametersPage = () => {
  
    const freightTypeId = 4;
    const api = useApi();
    const [insuranceClasses, setInsuranceClasses] = useState([]);
    const [customsClasses, setCustomsClasses] = useState([]);
    const [transportCountryParameters, setTransportCountryParameters] = useState([]);
  

    useEffect(() => {
        getInsuranceClasses();
        getCustomsClasses();
        getTransportCountryParameters();
      }, [])
    
      const getTransportCountryParameters = async() => {
        const data = await api.getTransportCountryParameters();
        if(data) {
          setTransportCountryParameters(data.results);
        }
      }


    const getInsuranceClasses = async() => {
        const data = await api.getInsuranceClassesByFreightTypeId(freightTypeId);
        if(data) {
          setInsuranceClasses(data.results);
        }
    }
    
      const getCustomsClasses = async() => {
        const data = await api.getCustomsClassesByFreightTypeId(freightTypeId);
        if(data) {
          setCustomsClasses(data.results);
        }
    }
  
    return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TransportCountryParameters transportCountryParameters={transportCountryParameters} getTransportCountryParameters={getTransportCountryParameters} insuranceClasses={insuranceClasses} customsClasses={customsClasses} />
      </Grid>
      <Grid item xs={4}>
        <TransportParameter />
      </Grid>
      <Grid item xs={4}>
        <InsuranceClasses freightTypeId={freightTypeId} insuranceClasses={insuranceClasses} getInsuranceClasses={getInsuranceClasses} getCountryParmeters={getTransportCountryParameters} />
      </Grid>
      <Grid item xs={4}>
        <CustomsClasses freightTypeId={freightTypeId} customsClasses={customsClasses} getCustomsClasses={getCustomsClasses} getCountryParmeters={getTransportCountryParameters} />
      </Grid>
    </Grid>
  )
}

export default TransportParametersPage
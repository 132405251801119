import { Box } from '@mui/material'
import React from 'react'

const Logo = () => {
  return (
    <Box>
        <img src="./logo.svg" height="35" />
    </Box>
  )
}

export default Logo
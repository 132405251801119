import { Tab, Tabs } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Link, Route, Routes, useLocation } from 'react-router-dom'
import RoadParametersPage from './RoadParametersPage'
import GlobalParametersPage from './GlobalParametersPage'
import SeaParametersPage from './SeaParametersPage'
import AirParametersPage from './AirParametersPage'
import useApi from '../hooks/useApi'
import AirFreightRatesPage from './AirFreightRatesPage'
import UsersPage from './UsersPage'
import GlobalContext from '../contexts/GlobalContext'
import TopBar from '../components/TopBar'
import Page from '../layout/Page'
import PageBody from '../layout/PageBody'
import TransportParametersPage from './TransportParametersPage'
import EmailsPage from './EmailsPage'

const SettingsPage = () => {

  const { user, setUser, token, setToken } = useContext(GlobalContext);
  const api = useApi();
  const location = useLocation();
  const [value, setValue] = useState("/settings");
  const [containers, setContainers] = useState([]);

  useEffect(() => {
    getContainers();
  }, [])

  const getContainers = async() => {
    const data = await api.getContainers();
    if(data) {
      setContainers(data.results);
    }
  }

  useEffect(() => {
    setValue(location.pathname);
  }, [location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }


  return (
    <Page>

      <TopBar label="Einstellungen">
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Allgemein" index={0} component={Link} to={'/settings'} value="/settings"></Tab>
            <Tab label="Landfracht" index={1} component={Link} to={'/settings/road'} value={"/settings/road"}></Tab>
            <Tab label="Seefracht" index={2} component={Link} to={'/settings/sea'} value="/settings/sea"></Tab>
            <Tab label="Luftfracht" index={3} component={Link} to={'/settings/air'} value="/settings/air"></Tab>
            <Tab label="Luftfracht Raten" index={4} component={Link} to={'/settings/airfreightrates'} value="/settings/airfreightrates"></Tab>
            <Tab label="Kleintransporte" index={5} component={Link} to={'/settings/transport'} value="/settings/transport"></Tab>
            {user.user_role_id === 1 ? (<Tab label="E-Mails" index={6} component={Link} to={'/settings/emails'} value="/settings/emails"></Tab>): null}
            {user.user_role_id === 1 ? (<Tab label="Benutzer" index={7} component={Link} to={'/settings/users'} value="/settings/users"></Tab>): null}
          </Tabs>
      </TopBar>

      <PageBody>
        <Routes>
            <Route path="/" element={<GlobalParametersPage />} />
            <Route path="/road" element={<RoadParametersPage />} />
            <Route path="/sea" element={<SeaParametersPage containers={containers} getContainers={getContainers} />} />
            <Route path="/air" element={<AirParametersPage />} />
            <Route path="/transport" element={<TransportParametersPage />} />
            <Route path="/airfreightrates" element={<AirFreightRatesPage />} />
            {user.user_role_id === 1 ? (<Route path="/emails" element={<EmailsPage />} />): null}
            {user.user_role_id === 1 ? (<Route path="/users" element={<UsersPage />} />): null}
          </Routes>
      </PageBody>
      
    </Page>
  )
}

export default SettingsPage
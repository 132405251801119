import { Box, Divider, Grid,  Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import CalculationArticles from './CalculationArticles';
import EnquiryItems from './EnquiryItems';

const EnquiryResult = ({calculation}) => {
  
    return (
    <Paper className="spacing-2" sx={{height:'100%'}}>
        {calculation.success ? (
        <Stack spacing={2}>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Stack spacing={2}>
                            <Typography variant="h2">Transportart</Typography>
                            <Box>{calculation.freight_type.name}</Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack spacing={2}>
                            <Typography variant="h2">Menge</Typography>
                            {calculation.volume && calculation.weight ? 
                                (<Box>{calculation.volume.toCommaString()} m³ / {calculation.weight.toCommaString()} kg</Box>) : 
                                (calculation.volume ? (<Box>{calculation.volume.toCommaString()} m³</Box>) : <Box>{calculation.weight.toCommaString()} kg</Box>)
                            }
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <CalculationArticles calculation={calculation} />
            {calculation.items ? (
            <EnquiryItems items={calculation.items} />
            ) : null}
            
        </Stack>
        ) : (
        <Typography variant="h2">Kein Ergebnis</Typography>
        )}
    </Paper>
  )
}

export default EnquiryResult
import { Button, InputAdornment, Paper, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import useApi from '../hooks/useApi';

const AirParameter = () => {
  
  const api = useApi();
  const [airParameter, setAirParameter] = useState({
		min_distance: '',
    max_distance: '',
		min_weight: '',
    max_weight: '',
		profit_import_rate: '',
		profit_export_rate: '',
		profit_cross_rate: '',
    insurance_coverage: '',
    margin: ''
	});
	const [isProcessing, setIsProcessing] = useState(false);


	useEffect(() => {
    getAirParameter();
	}, []);

  const getAirParameter = async() => {
    const data = await api.getAirParameter();
    if(data) {
      setAirParameter(data.result);
    }
  }

	const save = async() => {
    setIsProcessing(true);
    await api.updateAirParameter(airParameter);
		setIsProcessing(false);
  }

  return (
    <Paper className="spacing-2" sx={{height:'100%'}}>
			<Stack spacing={2} alignItems="flex-start">
				<Typography variant="h2">Allgemein</Typography>
				<TextField 
            size="small" fullWidth
            label="Min. Distanz"
            InputProps={{
              endAdornment: <InputAdornment position="end">km</InputAdornment>,
            }}
            value={airParameter.min_distance}
            onChange={(e) => setAirParameter({...airParameter, min_distance: e.target.value.toIntString()})}
        />
        <TextField 
            size="small" fullWidth
            label="Max. Distanz"
            InputProps={{
              endAdornment: <InputAdornment position="end">km</InputAdornment>,
            }}
            value={airParameter.max_distance}
            onChange={(e) => setAirParameter({...airParameter, max_distance: e.target.value.toIntString()})}
        />
				<TextField 
            size="small" fullWidth
            label="Min. Gewicht"
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
            value={airParameter.min_weight}
            onChange={(e) => setAirParameter({...airParameter, min_weight: e.target.value.toIntString()})}
        />
        <TextField 
            size="small" fullWidth
            label="Max. Gewicht"
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
            value={airParameter.max_weight}
            onChange={(e) => setAirParameter({...airParameter, max_weight: e.target.value.toIntString()})}
        />
				<TextField 
            size="small" fullWidth
            label="Profit Import"
            InputProps={{
              endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
            }}
            value={airParameter.profit_import_rate.toCommaString()}
            onChange={(e) => setAirParameter({...airParameter, profit_import_rate: e.target.value.toFloatString()})}
        />
				<TextField 
            size="small" fullWidth
            label="Profit Export"
            InputProps={{
              endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
            }}
            value={airParameter.profit_export_rate.toCommaString()}
            onChange={(e) => setAirParameter({...airParameter, profit_export_rate: e.target.value.toFloatString()})}
        />
				<TextField 
            size="small" fullWidth
            label="Profit Quer"
            InputProps={{
              endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
            }}
            value={airParameter.profit_cross_rate.toCommaString()}
            onChange={(e) => setAirParameter({...airParameter, profit_cross_rate: e.target.value.toFloatString()})}
        />
        <TextField 
            size="small" fullWidth
            label="Versicherungssumme"
            InputProps={{
              endAdornment: <InputAdornment position="end">EUR / kg</InputAdornment>,
            }}
            value={airParameter.insurance_coverage.toCommaString()}
            onChange={(e) => setAirParameter({...airParameter, insurance_coverage: e.target.value.toFloatString()})}
        />
        <TextField 
            size="small" fullWidth
            label="Marge"
            value={airParameter.margin.toCommaString()}
            onChange={(e) => setAirParameter({...airParameter, margin: e.target.value.toIntString()})}
        />
				<Stack direction="row" justifyContent="flex-end" sx={{width:'100%'}}>
          <Button variant="contained" disabled={isProcessing || !airParameter.min_distance || !airParameter.max_distance || !airParameter.min_weight || !airParameter.profit_import_rate || !airParameter.profit_export_rate || !airParameter.profit_cross_rate || !airParameter.margin} onClick={save}>Speichern</Button>
        </Stack>

			</Stack>
		</Paper>
  )
}

export default AirParameter
import axios from "axios";
import { useSnackbar } from 'notistack';
import GlobalContext from "../contexts/GlobalContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const useApi = () => {

    const { setUser, token, setToken } = useContext(GlobalContext);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const api = {
        
        // ####################
        // ### USER & LOGIN ###
        // ####################

        async login(request) {
            return await execute('post', 'public/users/login', request);
        },

        async logout() {
            return await execute('get', 'users/logout');
        },

        // users
        async getUsers() {
            return await execute('get', 'users');
        },

        async addUser(user) {
            return await execute('post', 'users', user);
        },

        async updateUser(user) {
            return await execute('put', 'users/' + user.id, user);
        },

        async removeUser(userId) {
            return await execute('delete', 'users/' + userId);
        },

        // user roles
        async getUserRoles() {
            return await execute('get', 'userroles');
        },

        // ##################
        // ### CALCULATOR ###
        // ##################

        async getLocationsAutocomplete(request) {
            return await execute('get', 'locations/autocomplete', {params:request});
        },

        async getLocation(request) {
            return await execute('get', 'locations/lookup', {params:request});
        },

        async addCalculation(request) {
            return await execute('post', 'calculations', request);
        },

        async setCalculationRoute(request) {
            return await execute('put', 'calculations/' + request.id + '/setroute', request);
        },

        // #################
        // ### ENQUIRIES ###
        // #################

        async addEnquiry(request) {
            return await execute('post', 'enquiries', request);
        },

        async getEnquiries(request) {
            return await execute('get', 'enquiries', {params:request});
        },

        async getEnquiry(id) {
            return await execute('get', 'enquiries/' + id);
        },


        // #####################
        // ### ENQUIRYSTATUS ###
        // #####################

        async addEnquiryStatus(request) {
            return await execute('post', 'enquirystatuses', request);
        },

        
        // ################
        // ### STATUSES ###
        // ################
        
        // statuses
        async getStatuses() {
            return await execute('get', 'statuses');
        },

        async addStatus(status) {
            return await execute('post', 'statuses', status);
        },

        async updateStatus(status) {
            return await execute('put', 'statuses/' + status.id, status);
        },

        async removeStatus(statusId) {
            return await execute('delete', 'statuses/' + statusId);
        },

        // ##################
        // ### PARAMETERS ###
        // ##################

        // countries
        async getCountries() {
            return await execute('get', 'countries');
        },

        async addCountry(country) {
            return await execute('post', 'countries', country);
        },

        async updateCountry(country) {
            return await execute('put', 'countries/' + country.id, country);
        },

        async removeCountry(countryId) {
            return await execute('delete', 'countries/' + countryId);
        },

        // currencies
        async getCurrencies() {
            return await execute('get', 'currencies');
        },

        async addCurrency(currency) {
            return await execute('post', 'currencies', currency);
        },

        async updateCurrency(currency) {
            return await execute('put', 'currencies/' + currency.id, currency);
        },

        async removeCurrency(currencyId) {
            return await execute('delete', 'currencies/' + currencyId);
        },

        // periods
        async getPeriods() {
            return await execute('get', 'periods');
        },

        async updatePeriods(roadParameter) {
            return await execute('put', 'periods', roadParameter); 
        },

        // freight types
        async getFreightTypes() {
            return await execute('get', 'freighttypes');
        },

        // season factors
        async getSeasonSurchargesByFreightTypeId(freightTypeId) {
            return await execute('get', 'seasonsurcharges/freighttype/' + freightTypeId);
        },

        async addSeasonSurcharge(seasonSurcharge) {
            return await execute('post', 'seasonsurcharges', seasonSurcharge);
        },

        async updateSeasonSurcharge(seasonSurcharge) {
            return await execute('put', 'seasonsurcharges/' + seasonSurcharge.id, seasonSurcharge);
        },

        async removeSeasonSurcharge(seasonSurchargeId) {
            return await execute('delete', 'seasonsurcharges/' + seasonSurchargeId);
        },

        // insurance classes
        async getInsuranceClassesByFreightTypeId(freightTypeId) {
            return await execute('get', 'insuranceclasses/freighttype/' + freightTypeId);
        },

        async addInsuranceClass(insuranceClass) {
            return await execute('post', 'insuranceclasses', insuranceClass);
        },

        async updateInsuranceClass(insuranceClass) {
            return await execute('put', 'insuranceclasses/' + insuranceClass.id, insuranceClass);
        },

        async removeInsuranceClass(insuranceClassId) {
            return await execute('delete', 'insuranceclasses/' + insuranceClassId);
        },

        // customs classes
        async getCustomsClassesByFreightTypeId(freightTypeId) {
            return await execute('get', 'customsclasses/freighttype/' + freightTypeId);
        },

        async addCustomsClass(customsClass) {
            return await execute('post', 'customsclasses', customsClass);
        },

        async updateCustomsClass(customsClass) {
            return await execute('put', 'customsclasses/' + customsClass.id, customsClass);
        },

        async removeCustomsClass(customsClassId) {
            return await execute('delete', 'customsclasses/' + customsClassId);
        },


        // ######################
        // ### ARTICLE GROUPS ###
        // ######################

        async getArticleGroups() {
            return await execute('get', 'articlegroups');
        },

        // ################
        // ### ARTICLES ###
        // ################

        async getArticles() {
            return await execute('get', 'articles');
        },

        async getArticlesByArticleGroupId(articleGroupId) {
            return await execute('get', 'articles/articlegroup/' + articleGroupId);
        },

        async addArticle(article) {
            return await execute('post', 'articles', article);
        },

        async updateArticle(article) {
            return await execute('put', 'articles/' + article.id, article);
        },

        async removeArticle(articleId) {
            return await execute('delete', 'articles/' + articleId);
        },

        // #######################
        // ### ROAD PARAMETERS ###
        // #######################

        // trucks
        async getTrucks() {
            return await execute('get', 'trucks');
        },

        async addTruck(truck) {
            return await execute('post', 'trucks', truck);
        },

        async updateTruck(truck) {
            return await execute('put', 'trucks/' + truck.id, truck);
        },

        async removeTruck(truckId) {
            return await execute('delete', 'trucks/' + truckId);
        },

        // road parameter
        async getRoadParameter() {
            return await execute('get', 'roadparameter');
        },

        async updateRoadParameter(roadParameter) {
            return await execute('put', 'roadparameter', roadParameter); 
        },

        // road country parameters
        async getRoadCountryParameters() {
            return await execute('get', 'roadcountryparameters');
        },

        async addRoadCountryParameter(roadCountryParameter) {
            return await execute('post', 'roadcountryparameters', roadCountryParameter);
        },

        async updateRoadCountryParameter(roadCountryParameter) {
            return await execute('put', 'roadcountryparameters/' + roadCountryParameter.id, roadCountryParameter);
        },

        async removeRoadCountryParameter(roadCountryParameterId) {
            return await execute('delete', 'roadcountryparameters/' + roadCountryParameterId);
        },


        // ######################
        // ### SEA PARAMETERS ###
        // ######################

        // sea parameter
        async getSeaParameter() {
            return await execute('get', 'seaparameter');
        },

        async updateSeaParameter(seaParameter) {
            return await execute('put', 'seaparameter', seaParameter); 
        },

        // sea country parameters
        async getSeaCountryParameters() {
            return await execute('get', 'seacountryparameters');
        },

        async addSeaCountryParameter(seaCountryParameter) {
            return await execute('post', 'seacountryparameters', seaCountryParameter);
        },

        async updateSeaCountryParameter(seaCountryParameter) {
            return await execute('put', 'seacountryparameters/' + seaCountryParameter.id, seaCountryParameter);
        },

        async removeSeaCountryParameter(seaCountryParameterId) {
            return await execute('delete', 'seacountryparameters/' + seaCountryParameterId);
        },

        // container types
        async getContainerTypes() {
            return await execute('get', 'containertypes');
        },

        async addContainerType(containerType) {
            return await execute('post', 'containertypes', containerType);
        },

        async updateContainerType(containerType) {
            return await execute('put', 'containertypes/' + containerType.id, containerType);
        },

        async removeContainerType(containerTypeId) {
            return await execute('delete', 'containertypes/' + containerTypeId);
        },

        // containers
        async getContainers() {
            return await execute('get', 'containers'); 
        },

        async addContainer(container) {
            return await execute('post', 'containers', container);
        },

        async updateContainer(container) {
            return await execute('put', 'containers/' + container.id, container);
        },

        async removeContainer(containerId) {
            return await execute('delete', 'containers/' + containerId);
        },

        // sea profits
        async getSeaProfits() {
            return await execute('get', 'seaprofits');
        },

        async addSeaProfit(seaProfit) {
            return await execute('post', 'seaprofits', seaProfit);
        },

        async updateSeaProfit(seaProfit) {
            return await execute('put', 'seaprofits/' + seaProfit.id, seaProfit);
        },

        async removeSeaProfit(seaProfitId) {
            return await execute('delete', 'seaprofits/' + seaProfitId);
        },

        // seaports
        async getSeaports() {
            return await execute('get', 'seaports');
        },

        async addSeaport(seaport) {
            return await execute('post', 'seaports', seaport);
        },

        async updateSeaport(seaport) {
            return await execute('put', 'seaports/' + seaport.id, seaport);
        },

        async removeSeaport(seaportId) {
            return await execute('delete', 'seaports/' + seaportId);
        },



        // ######################
        // ### AIR PARAMETERS ###
        // ######################

        // air parameter
        async getAirParameter() {
            return await execute('get', 'airparameter');
        },

        async updateAirParameter(airParameter) {
            return await execute('put', 'airparameter', airParameter); 
        },

        // air country parameters
        async getAirCountryParameters() {
            return await execute('get', 'aircountryparameters');
        },

        async addAirCountryParameter(airCountryParameter) {
            return await execute('post', 'aircountryparameters', airCountryParameter);
        },

        async updateAirCountryParameter(airCountryParameter) {
            return await execute('put', 'aircountryparameters/' + airCountryParameter.id, airCountryParameter);
        },

        async removeAirCountryParameter(airCountryParameterId) {
            return await execute('delete', 'aircountryparameters/' + airCountryParameterId);
        },

        // airports
        async getAirports() {
            return await execute('get', 'airports');
        },

        async getAirportsByCountryId(countryId) {
            return await execute('get', 'countries/' + countryId + '/airports');
        },

        async addAirport(airport) {
            return await execute('post', 'airports', airport);
        },

        async updateAirport(airport) {
            return await execute('put', 'airports/' + airport.id, airport);
        },

        async removeAirport(airportId) {
            return await execute('delete', 'airports/' + airportId);
        },

        // air freight rates
        async getAirFreightRates(originCountryId, destinationCountryId) {
            return await execute('get', 'airfreightrates/' + originCountryId + '/' + destinationCountryId);
        },

        async addOrUpdateAirFreightRates(airFreightRates) {
            return await execute('post', 'airfreightrates', airFreightRates);
        },


        // ############################
        // ### TRANSPORT PARAMETERS ###
        // ############################

        // road parameter
        async getTransportParameter() {
            return await execute('get', 'transportparameter');
        },

        async updateTransportParameter(roadParameter) {
            return await execute('put', 'transportparameter', roadParameter); 
        },

        // road country parameters
        async getTransportCountryParameters() {
            return await execute('get', 'transportcountryparameters');
        },

        async addTransportCountryParameter(transportCountryParameter) {
            return await execute('post', 'transportcountryparameters', transportCountryParameter);
        },

        async updateTransportCountryParameter(transportCountryParameter) {
            return await execute('put', 'transportcountryparameters/' + transportCountryParameter.id, transportCountryParameter);
        },

        async removeTransportCountryParameter(transportCountryParameterId) {
            return await execute('delete', 'transportcountryparameters/' + transportCountryParameterId);
        },


        // ##############
        // ### EMAILS ###
        // ##############

        async getEmails() {
            return await execute('get', 'emails');
        },

        async addEmail(email) {
            return await execute('post', 'emails', email);
        },

        async updateEmail(email) {
            return await execute('put', 'emails/' + email.id, email);
        },

        async removeEmail(emailId) {
            return await execute('delete', 'emails/' + emailId);
        },

    }

    const execute = async (method, url, request) => {

        const r = axios.create({
            baseURL: process.env.REACT_APP_SERVER_URL,
            headers: {'Authorization': 'Bearer '+ token}
        });

        switch(method) {
            case 'get':
                return await r.get(url, request)
                    .then((response) => {
                        return handleRespose(response);
                    })
                    .catch(function(error) {
                        return handleError(error);
                    });
            case 'post':
                return await r.post(url, request)
                    .then((response) => {
                        return handleRespose(response);
                    })
                    .catch(function(error) {
                        return handleError(error);
                    });
            case 'put':
                return await r.put(url, request)
                    .then((response) => {
                        return handleRespose(response);
                    })
                    .catch(function(error) {
                        return handleError(error);
                    });
            case 'delete':
                return await r.delete(url)
                    .then((response) => {
                        return handleRespose(response);
                    })
                    .catch(function(error) {
                        return handleError(error);
                    });
        }

        return false;

    }


    const handleRespose = (response) => {
        if(response.data.success) {
            return response.data;
        }
        else {
            if(response.data.errors) {
                Object.keys(response.data.errors).forEach(key => {
                    enqueueSnackbar(response.data.errors[key].join(' '), {variant: 'error'});
                });
            }
            else {
                enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
            }
            return false;
        }
    }

    const handleError = (error) => {

        if(error.response.status === 401) {
            navigate('/');
            setToken('');
            setUser(null);
        }
        else {
            enqueueSnackbar('Es ist ein Server Fehler aufgetreten.', {variant: 'error'});
        }        
    }

    return api;

}

export default useApi;



/*
HOW TO USE

const api = useApi();

const fetchData = async() => {
    const data = await api.getCountries();
    console.log(data);
}

*/
import { Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useState } from 'react'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import '../Prototypes';
import useApi from '../hooks/useApi';

const Containers = ({containers, getContainers, containerTypes}) => {
  
    const api = useApi();
    const [container, setContainer] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
  
    const add = () => {
      setContainer({
        container_type_id: '',
        name: '',
        volume: '',
        weight: '',
        code: ''
      })
    }

    const edit = (item) => {
      setContainer({
        id: item.id,
        container_type_id: item.container_type_id,
        name: item.name,
        volume: item.volume,
        weight: item.weight,
        code: item.code
      })
    }

    const save = async() => {
      setIsProcessing(true);
      const data = container.id ? await api.updateContainer(container) : await api.addContainer(container);
      if(data) {
        setContainer(null);
        getContainers();
      }
      setIsProcessing(false);
    }

    const remove = async() => {
      setIsProcessing(true);
      const data = await api.removeContainer(container.id);
      if(data) {
        setContainer(null);
        getContainers();
      }
      setIsProcessing(false);
    }

    const abort = () => {
      setContainer(null);
    }

    return (
    <>
      <Paper className="spacing-2">
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
            <Typography variant="h2">Container</Typography>
            <Button variant="outlined" onClick={add}>Hinzufügen</Button>
          </Stack>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow sx={{verticalAlign:'top'}}>
                  <TableCell><Typography variant="caption">Typ</Typography></TableCell>
                  <TableCell><Typography variant="caption">Bezeichnung</Typography></TableCell>
                  <TableCell><Typography variant="caption">Volumen<br/>m³</Typography></TableCell>
                  <TableCell><Typography variant="caption">Code</Typography></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {containers.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.container_type.name}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.volume}</TableCell>
                  <TableCell>{item.code}</TableCell>
                  <TableCell sx={{textAlign:'right'}}>
                    <IconButton onClick={() => edit(item)}>
                      <EditOutlinedIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Paper>
      <Modal open={container !== null} onClose={abort}>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{minHeight:'100%'}}>
          {container !== null ? (
          <Paper className="spacing-2">
            <Stack spacing={2}>
              <Typography variant="h2">Container</Typography>
              <FormControl fullWidth>
                <InputLabel size="small">Container Typ</InputLabel>
                <Select size="small"
                    fullWidth
                    value={container.container_type_id}
                    onChange={(e) => setContainer({...container, container_type_id: e.target.value})}
                    label="Container Typ"
                    >
                    {containerTypes.map((item) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                    size="small"
                    label="Bezeichnung"
                    value={container.name}
                    onChange={(e) => setContainer({...container, name:e.target.value})}
              />
              <TextField
                    size="small"
                    label="Code"
                    value={container.code}
                    onChange={(e) => setContainer({...container, code:e.target.value})}
              />
              <Stack direction="row" spacing={2}>
                <TextField
                    size="small"
                    label="Volumen"
                    InputProps={{
                      endAdornment: <InputAdornment position="start">m³</InputAdornment>,
                    }}
                    value={container.volume}
                    onChange={(e) => setContainer({...container, volume:e.target.value.toIntString()})}
                />
                <TextField
                    size="small"
                    label="Gewicht"
                    InputProps={{
                      endAdornment: <InputAdornment position="start">kg</InputAdornment>,
                    }}
                    value={container.weight}
                    onChange={(e) => setContainer({...container, weight:e.target.value.toIntString()})}
                />
              </Stack>

              <Stack direction="row" spacing={2}>
                {container.id ? (
                <Button variant="outlined" color="error" onClick={remove} disabled={isProcessing}>Löschen</Button>
                ) : null}
                <Button variant="outlined" onClick={abort}>Abbrechen</Button>
                <Button variant="contained" onClick={save} disabled={isProcessing || !container.name || !container.volume || !container.weight || !container.code}>Speichern</Button>
              </Stack>
            </Stack>
          </Paper>
          ): null}
        </Stack>
      </Modal>
    </>
  )
}

export default Containers
import { Stack } from '@mui/material'
import React from 'react'
import Users from '../components/Users'

const UsersPage = () => {
  return (
    <Stack direction="row">
      <Users />
    </Stack>
  )
}

export default UsersPage
import React, { useEffect, useState } from 'react'
import Trucks from '../components/Trucks'
import RoadCountryParameters from '../components/RoadCountryParameters'
import RoadParameter from '../components/RoadParameter'
import InsuranceClasses from '../components/InsuranceClasses'
import CustomsClasses from '../components/CustomsClasses'
import useApi from '../hooks/useApi'
import { Grid } from '@mui/material'
import SeasonSurcharges from '../components/SeasonSurcharges'

const RoadParametersPage = () => {
  
  const freightTypeId = 1;
  const api = useApi();
  const [trucks, setTrucks] = useState([]);
  const [insuranceClasses, setInsuranceClasses] = useState([]);
  const [customsClasses, setCustomsClasses] = useState([]);
  const [roadCountryParameters, setRoadCountryParameters] = useState([]);
  
  useEffect(() => {
    getTrucks();
    getInsuranceClasses();
    getCustomsClasses();
    getRoadCountryParameters();
  }, [])

  const getRoadCountryParameters = async() => {
    const data = await api.getRoadCountryParameters();
    if(data) {
      setRoadCountryParameters(data.results);
    }
  }

  const getTrucks = async() => {
    const data = await api.getTrucks();
    if(data) {
      setTrucks(data.results);
    }
  }

  const getInsuranceClasses = async() => {
    const data = await api.getInsuranceClassesByFreightTypeId(freightTypeId);
    if(data) {
      setInsuranceClasses(data.results);
    }
	}

  const getCustomsClasses = async() => {
    const data = await api.getCustomsClassesByFreightTypeId(freightTypeId);
    if(data) {
      setCustomsClasses(data.results);
    }
	}

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RoadCountryParameters trucks={trucks} roadCountryParameters={roadCountryParameters} getRoadCountryParameters={getRoadCountryParameters} insuranceClasses={insuranceClasses} customsClasses={customsClasses} />
      </Grid>
      <Grid item xs={4}>
        <RoadParameter />
      </Grid>
      <Grid item xs={4}>
        <InsuranceClasses freightTypeId={freightTypeId} insuranceClasses={insuranceClasses} getInsuranceClasses={getInsuranceClasses} getCountryParmeters={getRoadCountryParameters} />
      </Grid>
      <Grid item xs={4}>
        <CustomsClasses freightTypeId={freightTypeId} customsClasses={customsClasses} getCustomsClasses={getCustomsClasses} getCountryParmeters={getRoadCountryParameters} />
      </Grid>
      <Grid item xs={4}>
        <Trucks trucks={trucks} getTrucks={getTrucks} />
      </Grid>
      <Grid item xs={4}>
        <SeasonSurcharges freightTypeId={freightTypeId} />
      </Grid>
    </Grid>
  )
}

export default RoadParametersPage
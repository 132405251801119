import { useContext, useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import SettingsPage from "../pages/SettingsPage";
import CalculatorPage from "../pages/CalculatorPage";
import Menu from "./Menu";
import useApi from "../hooks/useApi";
import UsersPage from "../pages/UsersPage";
import GlobalContext from "../contexts/GlobalContext";
import EnquiriesPage from "../pages/EnquiriesPage";
import MainContext from "../contexts/MainContext";

const Main = () => {

    const { user } = useContext(GlobalContext);
    const api = useApi();
    const [countries, setCountries] = useState([]);
    const [freightTypes, setFreightTypes] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [currency, setCurrency] = useState(null);
    const [statuses, setStatuses] = useState([]);
  
    useEffect(() => {
      getCountries();
      getFreightTypes();
      getCurrencies();
      getStatuses();
    },[]);
  
    const getCountries = async() => {
        const data = await api.getCountries();
        if(data) {
            setCountries(data.results);
        }
    }
  
    const getFreightTypes = async() => {
        const data = await api.getFreightTypes();
        if(data) {
            setFreightTypes(data.results);
        }
    }

    const getCurrencies = async() => {
        const data = await api.getCurrencies();
        if(data) {
            setCurrencies(data.results);
            setCurrency(data.results[0]);
        }
    }

    const getStatuses = async() => {
        const data = await api.getStatuses();
        if(data) {
            setStatuses(data.results);
        }
    }
  
    return (
        <MainContext.Provider value={{countries, getCountries, setCountries, currencies, getCurrencies, setCurrencies, freightTypes, getFreightTypes, setFreightTypes, currency, setCurrency, statuses, getStatuses, setStatuses}}>
            <Stack direction="row" sx={{width:'100%', height:'100%'}}>
                <Menu />
                <Box sx={{flexGrow:1}}>
                    <Routes>

                        <Route path="/" element={<EnquiriesPage />} />
                        
                        <Route path="/calculator" element={<CalculatorPage />} />
                        
                        {[1,2].includes(user.user_role_id) ? (
                        <Route path="/settings/*" element={<SettingsPage countries={countries} getCountries={getCountries} />} />
                        ) : null }
                        
                        {user.user_role_id === 1 ? (
                        <Route path="/users" element={<UsersPage />} />
                        ) : null }

                    </Routes>
                </Box>
            </Stack>
        </MainContext.Provider>
    )
}

export default Main
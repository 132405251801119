import { Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Paper, Select, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import useApi from '../hooks/useApi'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const Users = () => {
  
    const api = useApi();
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState(null);
    const [userRoles, setUserRoles] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
  
    useEffect(() => {
        getUsers();
        getUserRoles();
    }, []);

    const getUsers = async() => {
        const data = await api.getUsers();
        if(data) {
            setUsers(data.results);
        }
    }

    const getUserRoles = async() => {
        const data = await api.getUserRoles();
        if(data) {
            setUserRoles(data.results);
        }
    }

    const add = () => {
        setUser({
            user_role_id:'',
            username: '',
            first_name: '',
            last_name: '',
            email: '',
            password: ''
        });
    }

    const edit = (item) => {
        setUser(item);
    }

    const save = async() => {
        setIsProcessing(true);
        const data = user.id ? await api.updateUser(user) : await api.addUser(user);
        if(data) {
            setUser(null);
            getUsers();
        }
        setIsProcessing(false);
    }

    const remove = async() => {
        setIsProcessing(true);
        const data = await api.removeUser(user.id);
        if(data) {
            setUser(null);
            getUsers();
        }
        setIsProcessing(false);
    }

    const abort = () => {
        setUser(null);
    }

    return (
    <>
    <Paper className="spacing-2">
        <Stack spacing={2}>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                <Typography variant="h2">Benutzer</Typography>
                <Button variant="outlined" onClick={add}>Hinzufügen</Button>
            </Stack>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography variant="caption">Vorname</Typography></TableCell>
                            <TableCell><Typography variant="caption">Nachname</Typography></TableCell>
                            <TableCell><Typography variant="caption">E-Mail</Typography></TableCell>
                            <TableCell><Typography variant="caption">Benutzername</Typography></TableCell>
                            <TableCell><Typography variant="caption">Rolle</Typography></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>{item.first_name}</TableCell>
                            <TableCell>{item.last_name}</TableCell>
                            <TableCell>{item.email}</TableCell>
                            <TableCell>{item.username}</TableCell>
                            <TableCell>{item.user_role.name}</TableCell>
                            <TableCell sx={{textAlign:'right'}}>
                                <IconButton onClick={() => edit(item)}>
                                    <EditOutlinedIcon fontSize="small" />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    </Paper>
    <Modal open={user !== null} onClose={abort}>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{minHeight:'100%'}}>
            {user !== null ? (
            <Paper className="spacing-2">
                <Stack spacing={2}>
                    <Typography variant="h2">Benutzer</Typography>
                    <Stack direction="row" spacing={2}>
                        <TextField
                            size="small"
                            label="Vorname"
                            value={user.first_name}
                            onChange={(e) => setUser({...user, first_name:e.target.value})}
                        />
                        <TextField
                            size="small"
                            label="Nachname"
                            value={user.last_name}
                            onChange={(e) => setUser({...user, last_name:e.target.value})}
                        />
                    </Stack>
                    <TextField
                        size="small"
                        label="E-Mail"
                        value={user.email}
                        onChange={(e) => setUser({...user, email:e.target.value})}
                    />
                    <FormControl fullWidth>
                        <InputLabel size="small" id="select-label">Benutzerrolle</InputLabel>
                        <Select size="small"
                            fullWidth
                            labelId="select-label"
                            value={user.user_role_id}
                            onChange={(e) => setUser({...user, user_role_id: e.target.value})}
                            label="Benutzerrolle"
                            >
                            {userRoles.map((item) => (
                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        size="small"
                        label="Benutzername"
                        value={user.username}
                        onChange={(e) => setUser({...user, username:e.target.value})}
                    />
                    <TextField
                        size="small"
                        label="Passwort"
                        type="password"
                        autoComplete="new-password"
                        value={user.password}
                        onChange={(e) => setUser({...user, password:e.target.value})}
                    />

                    <Stack direction="row" spacing={2}>
                        {user.id ? (
                        <Button variant="outlined" color="error" onClick={remove} disabled={isProcessing}>Löschen</Button>
                        ) : null}
                        <Button variant="outlined" onClick={abort}>Abbrechen</Button>
                        <Button variant="contained" onClick={save} disabled={isProcessing || !user.first_name || !user.last_name || !user.email || !user.user_role_id || !user.username || (!user.id && !user.password)}>Speichern</Button>
                    </Stack>

                </Stack>
            </Paper>
            ): null}
        </Stack>
    </Modal>
    </>
  )
}

export default Users
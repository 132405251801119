import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import React from 'react'

const EnquiryItems = ({items}) => {
  return (
    <Box>
        <Stack>
            <Typography variant="h2">Gegenstände</Typography>
            <TableContainer>
                <Table>
                    <TableBody>
                        {items.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell sx={{fontSize:'inherit'}}>{item.quantity}</TableCell>
                            <TableCell sx={{fontSize:'inherit'}}>{item.description}</TableCell>
                            <TableCell sx={{fontSize:'inherit'}}>L {item.length} cm x B {item.width} cm x H {item.height} cm</TableCell>
                            <TableCell sx={{fontSize:'inherit'}}>{item.weight} kg</TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    </Box>
  )
}

export default EnquiryItems
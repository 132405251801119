import { Button, IconButton, InputAdornment, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, {  useState } from 'react'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import '../Prototypes';
import useApi from '../hooks/useApi';

const CustomsClasses = ({freightTypeId, customsClasses, getCustomsClasses, getCountryParmeters}) => {
  
	const api = useApi();
	const [customsClass, setCustomsClass] =  useState(null);
	const [isProcessing, setIsProcessing] =  useState(false);

	const add = () => {
		setCustomsClass({
			freight_type_id: freightTypeId,
			class: '',
			name: '',
			premium: '',
			rate: ''
		});
	}

	const edit = (item) => {
		setCustomsClass({
			id: item.id,
			class: item.class,
			name: item.name,
			premium: item.premium,
			rate: item.rate
		});
	}

	const save = async() => {
		setIsProcessing(true);
		const data = customsClass.id ? await api.updateCustomsClass(customsClass) : await api.addCustomsClass(customsClass);
		if(data) {
			setCustomsClass(null);
			getCustomsClasses();
			getCountryParmeters();
		}
		setIsProcessing(false);
	}

	const remove = async() => {
		setIsProcessing(true);
		const data = await api.removeCustomsClass(customsClass.id);
		if(data) {
			setCustomsClass(null);
			getCustomsClasses();
		}
		setIsProcessing(false);
	}

	const abort = () => {
		setCustomsClass(null)
	}
	
	return (
    <>
		<Paper className="spacing-2" sx={{height:'100%'}}>
			<Stack spacing={2}>
				<Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
					<Typography variant="h2">Zollklassen</Typography>
					<Button variant="outlined" onClick={add}>Hinzufügen</Button>
				</Stack>
				<TableContainer>
					<Table size="small">
						<TableHead>
							<TableRow sx={{verticalAlign:'top'}}>
								<TableCell><Typography variant="caption">Klasse</Typography></TableCell>
								<TableCell><Typography variant="caption">Bezeichnung</Typography></TableCell>
								<TableCell><Typography variant="caption">Kosten<br/>EUR</Typography></TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{customsClasses.map((item) => (
							<TableRow key={item.id}>
								<TableCell>{item.class}</TableCell>
								<TableCell>{item.name}</TableCell>
								<TableCell>{item.rate.toCommaString()}</TableCell>
								<TableCell sx={{textAlign:'right'}}>
									<IconButton onClick={() => edit(item)}>
											<EditOutlinedIcon fontSize="small" />
									</IconButton>
								</TableCell>
							</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Stack>
		</Paper>
		<Modal open={customsClass !== null} onClose={abort}>
				<Stack direction="row" alignItems="center" justifyContent="center" sx={{minHeight:'100%'}}>
				{customsClass !== null ? (
				<Paper className="spacing-2">
					<Stack spacing={2}>
						<Typography variant="h2">Zollklasse</Typography>
						<TextField 
							size="small" fullWidth
							label="Klasse"
							value={customsClass.class}
							onChange={(e) => setCustomsClass({...customsClass, class: e.target.value})}
						/>
						<TextField 
							size="small" fullWidth
							label="Bezeichnung"
							value={customsClass.name}
							onChange={(e) => setCustomsClass({...customsClass, name: e.target.value})}
						/>
						<TextField 
							size="small" fullWidth
							label="Kosten"
							InputProps={{
								endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
							}}
							value={customsClass.rate.toCommaString()}
							onChange={(e) => setCustomsClass({...customsClass, rate: e.target.value.toFloatString()})}
						/>
						<Stack direction="row" spacing={2}>
								{customsClass.id ? (
								<Button variant="outlined" color="error" onClick={remove} disabled={isProcessing}>Löschen</Button>
								) : null}
								<Button variant="outlined" onClick={abort}>Abbrechen</Button>
								<Button variant="contained" onClick={save} disabled={isProcessing || !customsClass.name || !customsClass.rate}>Speichern</Button>
						</Stack>
					</Stack>
				</Paper>
				) : null}
				</Stack>
		</Modal>
	</>
  )
}

export default CustomsClasses
import { Button, IconButton, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, {  useState } from 'react'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import '../Prototypes';
import useApi from '../hooks/useApi';

const ContainerTypes = ({containerTypes, getContainerTypes, getContainers}) => {
  
  const api = useApi();
  const [containerType, setContainerType] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const add = () => {
    setContainerType({
      name: '',
      code: '',
    });
  }

  const edit = (item) => {
    setContainerType(item);
  }

  const save = async() => {
    setIsProcessing(true);
    const data = containerType.id ? await api.updateContainerType(containerType) : await api.addContainerType(containerType);
    if(data) {
      setContainerType(null);
      getContainerTypes();
      getContainers();
    }
    setIsProcessing(false);
  }

  const remove = async() => {
    setIsProcessing(true);
    const data = await api.removeContainerType(containerType.id);
    if(data) {
      setContainerType(null);
      getContainerTypes();
    }
    setIsProcessing(false);
  }

  const abort = () => {
    setContainerType(null);
  }
  
  return (
    <>
      <Paper className="spacing-2" sx={{height:'100%'}}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
            <Typography variant="h2">Container Typen</Typography>
            <Button variant="outlined" onClick={add}>Hinzufügen</Button>
          </Stack>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><Typography variant="caption">Bezeichnung</Typography></TableCell>
                  <TableCell><Typography variant="caption">Code</Typography></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {containerTypes.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.code}</TableCell>
                  <TableCell sx={{textAlign:'right'}}>
                    <IconButton onClick={() => edit(item)}>
                      <EditOutlinedIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Paper>
      <Modal open={containerType !== null} onClose={abort}>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{minHeight:'100%'}}>
          {containerType !== null ? (
          <Paper className="spacing-2">
            <Stack spacing={2}>
              <Typography variant="h2">Container Typ</Typography>
              <TextField
                size="small"
                label="Bezeichnung"
                value={containerType.name}
                onChange={(e) => setContainerType({...containerType, name:e.target.value})}
              />
              <TextField
                size="small"
                label="Code"
                value={containerType.code}
                onChange={(e) => setContainerType({...containerType, code:e.target.value})}
              />
              <Stack direction="row" spacing={2}>
                {containerType.id ? (
                <Button variant="outlined" color="error" onClick={remove} disabled={isProcessing}>Löschen</Button>
                ) : null}
                <Button variant="outlined" onClick={abort}>Abbrechen</Button>
                <Button variant="contained" onClick={save} disabled={isProcessing || !containerType.name || !containerType.code}>Speichern</Button>
              </Stack>
            </Stack>
          </Paper>
          ): null}
        </Stack>
      </Modal>
    </>
  )
}

export default ContainerTypes
import { Box, Tooltip } from '@mui/material'
import React from 'react'
import DirectionsBoatOutlinedIcon from '@mui/icons-material/DirectionsBoatOutlined';
import AirplanemodeActiveOutlinedIcon from '@mui/icons-material/AirplanemodeActiveOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

const FreightTypeIcon = ({id}) => {
  return (
    <>
        {(() => {
        switch (id) {
        case 1:
            return <LocalShippingOutlinedIcon fontSize="medium" />;
        case 2:
            return <DirectionsBoatOutlinedIcon fontSize="medium" />;
        case 3:
            return <AirplanemodeActiveOutlinedIcon fontSize="medium" />;
        case 4:
            return <Inventory2OutlinedIcon fontSize="medium" />;
        default:
            return <ReportProblemOutlinedIcon fontSize="medium" />;
        }
        })()}
    </>
  )
}

export default FreightTypeIcon
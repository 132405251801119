import './App.css';
import { Box } from '@mui/system';
import { useState } from 'react';
import { lightTheme, darkTheme } from './Themes';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import GlobalContext from './contexts/GlobalContext';
import Login from './components/Login';
import Main from './components/Main';

function App() {

  const [user, setUser] = useState(null);
  const [token, setToken] = useState('');
  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState(null);

  return (
    
    <ThemeProvider theme={darkTheme}>
    <CssBaseline />
      <GlobalContext.Provider value={{user, setUser, token, setToken, currencies, setCurrencies, currency, setCurrency}}>
        <Box className="App" sx={{width:'100%', height:'100%', overflow:'hidden'}}>
          {user !== null ? (<Main />) : (<Login />)}
        </Box>
      </GlobalContext.Provider>
    </ThemeProvider>
  );
}

export default App;

import { Autocomplete, Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react'
import '../Prototypes';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import useApi from '../hooks/useApi';
import MainContext from '../contexts/MainContext';
import Search from './Search';


const RoadCountryParameters = ({roadCountryParameters, getRoadCountryParameters, trucks, insuranceClasses, customsClasses}) => {
  
  const {countries} = useContext(MainContext);
  const api = useApi();
  const [roadCountryParameter, setRoadCountryParameter] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isProcessing, setIsProcessing] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    setFilteredItems(roadCountryParameters.filter(parameter => (search === '' || parameter.country.name.toLowerCase().includes(search.toLowerCase()))));
    setPage(0);
  }, [roadCountryParameters, search])


  const getTransportRate = (parameter, truckId, type) => {
    if(parameter.transport_rates) {
      const transportRate = parameter.transport_rates.find(obj => {
          return obj.truck_id === truckId;
      });
      return transportRate && transportRate[type] ? transportRate[type] : '';
    }
    return '';
  }

  const setTransportRate = (truckId, type, rate) => {
    var updated = false;
    var transportRates = roadCountryParameter.transport_rates.map(transportRate => {
      if (transportRate.truck_id === truckId) {
        updated = true;
        return {...transportRate, [type]:rate.toFloatString()};
      }
      else {
        return transportRate;
      }
    });
    if(!updated) {
      transportRates.push({truck_id:truckId, [type]:rate.toFloatString()})
    }
    setRoadCountryParameter({...roadCountryParameter, transport_rates:transportRates})
  }

  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const getCountryById = (id) => {
    const items = countries.filter((item) => item.id === id);
    return items.length ? items[0] : null; 
  }

  const isFormDisabled = (parameter, trucks) => {
    if(!parameter.id && !parameter.country_id) return true;
    if(!parameter.loading_rate || !parameter.unloading_rate || !parameter.packaging_rate) return true;
    if(parameter.transport_rates.length < trucks.length) return true;
    for(let i = 0; i < parameter.transport_rates.length; i++) {
      if(!parameter.transport_rates[i].truck_rate || !parameter.transport_rates[i].ferry_rate) return true;
    }
  }

  const add = () => {
    setRoadCountryParameter({
      country_id: '',
      insurance_class_id : '',
      customs_class_id : '',
      loading_rate: '',
      unloading_rate: '',
      packaging_rate: '',
      transport_rates: []
    });
  }

  const edit = (parameter) => {
    setRoadCountryParameter({
      id: parameter.id,
      _country_id : parameter.country_id,
      insurance_class_id : parameter.insurance_class_id,
      customs_class_id : parameter.customs_class_id,
      loading_rate: parameter.loading_rate,
      unloading_rate: parameter.unloading_rate,
      packaging_rate: parameter.packaging_rate,
      transport_rates : parameter.transport_rates
    }); 
  }

  const save = async() => {
    setIsProcessing(true);
    const data = roadCountryParameter.id ? await api.updateRoadCountryParameter(roadCountryParameter) : await api.addRoadCountryParameter(roadCountryParameter);
    if(data) {
      setRoadCountryParameter(null);
      getRoadCountryParameters();
    }
    setIsProcessing(false);
  }

  const remove = async() => {
    setIsProcessing(true);
    const data = await api.removeRoadCountryParameter(roadCountryParameter.id);
    if(data) {
      setRoadCountryParameter(null);
      getRoadCountryParameters();
    }
    setIsProcessing(false);
  }

  const abort = () => {
    setRoadCountryParameter(null);
  }

  return (
      <>
        <Paper className="spacing-2">
          <Stack spacing={2}>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Typography variant="h2">Kosten je Land</Typography>
              <Button variant="outlined" onClick={add}>Hinzufügen</Button>
            </Stack>
            <Search search={search} setSearch={setSearch} />
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={6}></TableCell>
                    <TableCell colSpan={trucks.length}><Typography variant="overline">Transportkosten</Typography></TableCell>
                    <TableCell colSpan={trucks.length}><Typography variant="overline">Fährkosten</Typography></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow sx={{verticalAlign:'top'}}>
                    <TableCell sx={{width:'200px'}}><Typography variant="caption">Land</Typography></TableCell>
                    <TableCell><Typography variant="caption">Beladen<br/>EUR / m³</Typography></TableCell>
                    <TableCell><Typography variant="caption">Entladen<br/>EUR / m³</Typography></TableCell>
                    <TableCell><Typography variant="caption">Verpackung<br/>EUR / m³</Typography></TableCell>
                    <TableCell><Typography variant="caption">Versicherung</Typography></TableCell>
                    <TableCell><Typography variant="caption">Zoll</Typography></TableCell>
                    {trucks.map((truck) => (
                    <TableCell key={truck.id}><Typography variant="caption">{truck.name}<br/>EUR / km</Typography></TableCell>
                    ))}
                    {trucks.map((truck) => (
                    <TableCell key={truck.id}><Typography variant="caption">{truck.name}<br/>EUR / km</Typography></TableCell>
                    ))}
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((parameter) => {
                  return (
                    <TableRow key={parameter.id}>
                      <TableCell>{parameter.country.name}</TableCell>
                      <TableCell>{parameter.loading_rate.toCommaString()}</TableCell>
                      <TableCell>{parameter.unloading_rate.toCommaString()}</TableCell>
                      <TableCell>{parameter.packaging_rate.toCommaString()}</TableCell>
                      <TableCell>{parameter.insurance_class.name}</TableCell>
                      <TableCell>{parameter.customs_class.name}</TableCell>
                      {trucks.map((truck) => (
                      <TableCell key={truck.id}>{getTransportRate(parameter, truck.id, 'truck_rate').toCommaString()}</TableCell>
                      ))}
                      {trucks.map((truck) => (
                      <TableCell key={truck.id}>{getTransportRate(parameter, truck.id, 'ferry_rate').toCommaString()}</TableCell>
                      ))}
                      <TableCell sx={{textAlign:'right'}}>
                        <IconButton onClick={() => edit(parameter)}>
                          <EditOutlinedIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination 
                      count={filteredItems.length} 
                      page={page} 
                      rowsPerPage={rowsPerPage} 
                      rowsPerPageOptions={[]}
                      onPageChange={onPageChange}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Stack>
        </Paper>
        <Modal open={roadCountryParameter !== null} onClose={abort}>
          <Stack direction="row" alignItems="center" justifyContent="center" sx={{minHeight:'100%'}}>
            {roadCountryParameter !== null ? (
            <Paper className="spacing-2">
              <Stack spacing={2}>
                <Typography variant="h2">Kosten</Typography>
                <Autocomplete size="small"
                  options={countries}
                  value={getCountryById(roadCountryParameter.id ? roadCountryParameter._country_id : roadCountryParameter.country_id)}
                  onChange={(e, value) => setRoadCountryParameter({...roadCountryParameter, country_id: value ? value.id : null})}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} label="Land" />}
                  disabled={roadCountryParameter.id ? true : false}
                />      
                <TextField
                  size="small"
                  label="Beladen"
                  InputProps={{
                    endAdornment: <InputAdornment position="start">EUR / m³</InputAdornment>,
                  }}
                  value={roadCountryParameter.loading_rate.toCommaString()}
                  onChange={(e) => setRoadCountryParameter({...roadCountryParameter, loading_rate:e.target.value.toFloatString()})}
                />
                <TextField
                  size="small"
                  label="Entladen"
                  InputProps={{
                    endAdornment: <InputAdornment position="start">EUR / m³</InputAdornment>,
                  }}
                  value={roadCountryParameter.unloading_rate.toCommaString()}
                  onChange={(e) => setRoadCountryParameter({...roadCountryParameter, unloading_rate:e.target.value.toFloatString()})}
                />
                <TextField
                  size="small"
                  label="Verpackung"
                  InputProps={{
                    endAdornment: <InputAdornment position="start">EUR / m³</InputAdornment>,
                  }}
                  value={roadCountryParameter.packaging_rate.toCommaString()}
                  onChange={(e) => setRoadCountryParameter({...roadCountryParameter, packaging_rate:e.target.value.toFloatString()})}
                />
                <FormControl fullWidth>
                  <InputLabel size="small">Versicherungsklasse</InputLabel>
                  <Select size="small"
                      fullWidth
                      value={roadCountryParameter.insurance_class_id}
                      onChange={(e) => setRoadCountryParameter({...roadCountryParameter, insurance_class_id: e.target.value})}
                      label="Versicherungsklasse"
                      >
                      {insuranceClasses.map((item) => (
                          <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel size="small">Zollklasse</InputLabel>
                  <Select size="small"
                      fullWidth
                      value={roadCountryParameter.customs_class_id}
                      onChange={(e) => setRoadCountryParameter({...roadCountryParameter, customs_class_id: e.target.value})}
                      label="Zollklasse"
                      >
                      {customsClasses.map((item) => (
                          <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <Stack direction="row" spacing={2}>
                  <Stack spacing={2}>
                    <Typography variant="button">Transportkosten</Typography>
                    {trucks.map((truck) => (
                    <TextField key={truck.id}
                      size="small"
                      label={truck.name}
                      InputProps={{
                        endAdornment: <InputAdornment position="start">EUR / km</InputAdornment>,
                      }}
                      value={getTransportRate(roadCountryParameter, truck.id, 'truck_rate').toCommaString()}
                      onChange={(e) => setTransportRate(truck.id, 'truck_rate', e.target.value)}
                    />
                    ))}
                  </Stack>
                  <Stack spacing={2}>
                    <Typography variant="button">Fährkosten</Typography>
                    {trucks.map((truck) => (
                    <TextField key={truck.id}
                      size="small"
                      label={truck.name}
                      InputProps={{
                        endAdornment: <InputAdornment position="start">EUR / km</InputAdornment>,
                      }}
                      value={getTransportRate(roadCountryParameter, truck.id, 'ferry_rate').toCommaString()}
                      onChange={(e) => setTransportRate(truck.id, 'ferry_rate', e.target.value)}
                    />
                    ))}
                  </Stack>
                </Stack>
                
                <Stack direction="row" spacing={2}>
                  {roadCountryParameter.id ? (
                  <Button variant="outlined" color="error" onClick={remove} disabled={isProcessing}>Löschen</Button>
                  ) : null}
                  <Button variant="outlined" onClick={abort}>Abbrechen</Button>
                  <Button variant="contained" onClick={save} disabled={isProcessing || isFormDisabled(roadCountryParameter, trucks)}>Speichern</Button>
                </Stack>
              </Stack>
            </Paper>
            ): null}
          </Stack>
        </Modal>
      </>
  )
}

export default RoadCountryParameters
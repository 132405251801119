import { createContext } from "react";

const MainContext = createContext({
    countries: [],
    getCountries: {},
    setCountries: (countries) => {},
    currencies: [],
    getCurrencies: {},
    setCurrencies: (currencies) => {},
    freightTypes: [],
    getFreightTypes: {},
    setFreightTypes: (freightTypes) => {},
    currency: null,
    setCurrency: {},
    statuses: [],
    getStatuses: {},
    setStatuses: (statuses) => {}
})

export default MainContext;
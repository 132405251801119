import React, { useContext, useEffect } from 'react'
import MainContext from '../contexts/MainContext';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

const StatusSelect = ({statusId, setStatusId}) => {
  
    const {statuses} = useContext(MainContext);
  
    const get = (items) => {
        let results = [];
        items.map((item) => {
            results.push(item);
            item.statuses.map((subitem) => {
                results.push(subitem);
            });
        });
        return results;
    }

    return (
    <FormControl fullWidth>
        <InputLabel size="small">Status</InputLabel>
        <Select size="small"
            fullWidth
            value={statusId ? statusId : ''}
            onChange={(e) => setStatusId(e.target.value)}
            label="Status"
            >
            <MenuItem key={0} value="">&nbsp;</MenuItem>
            {get(statuses).map((item) => (
            <MenuItem key={item.id} value={item.id}>
                {item.status_id ? (
                <Typography sx={{paddingLeft:'15px'}}>{item.name}</Typography>
                ) : (
                <Typography sx={{fontWeight:'bold', textTransform:'uppercase'}}>{item.name}</Typography>
                )}
            </MenuItem>
            ))}
        </Select>
    </FormControl>
  )
}

export default StatusSelect
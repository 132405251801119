import { Button, FormControl, FormControlLabel, FormLabel, Modal, Paper, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import useApi from '../hooks/useApi';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';

const AddEnquiry = ({calculation, currency}) => {
  
    const api = useApi();
    const [enquiry, setEnquiry] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const add = () => {
        setEnquiry({
            first_name: '',
            last_name: '',
            company_name: '',
            phone: '',
            email: '',
            calculation_id: calculation.id,
            volume: calculation.volume ? calculation.volume : null,
            weight_acw: calculation.weight ? calculation.weight : null,
            lang: 'de',
            currency_id: currency.id
        });
    }

    const save = async() => {
        
        setIsProcessing(true);
        const data = await api.addEnquiry(enquiry);
        if(data) {
          setEnquiry(null);
          setIsButtonDisabled(true);
        }
        setIsProcessing(false);

    }

    const abort = () => {
        setEnquiry(null);
    }

    const onPhoneChange = (newValue) => {
        setEnquiry({...enquiry, phone:newValue})
    }

    return (
    <>
        <Button variant="contained" onClick={add} disabled={isButtonDisabled}>Anfrage hinzufügen</Button>
        <Modal open={enquiry !== null} onClose={abort}>
            <Stack direction="row" alignItems="center" justifyContent="center" sx={{minHeight:'100%'}}>
                {enquiry !== null ? (
                <Paper className="spacing-2">
                    <Stack spacing={2}>
                        <Typography variant="h2">Anfrage</Typography>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                size="small"
                                label="Vorname"
                                value={enquiry.first_name}
                                onChange={(e) => setEnquiry({...enquiry, first_name:e.target.value})}
                            />
                            <TextField
                                size="small"
                                label="Nachname"
                                value={enquiry.last_name}
                                onChange={(e) => setEnquiry({...enquiry, last_name:e.target.value})}
                            />
                        </Stack>
                        <TextField
                            size="small"
                            label="Firma"
                            value={enquiry.company_name}
                            onChange={(e) => setEnquiry({...enquiry, company_name:e.target.value})}
                        />
                        <MuiTelInput
                            defaultCountry="DE"
                            size="small"
                            label="Telefon"
                            value={enquiry.phone}
                            onChange={onPhoneChange}
                        />
                        <TextField
                            size="small"
                            label="E-Mail"
                            value={enquiry.email}
                            onChange={(e) => setEnquiry({...enquiry, email:e.target.value})}
                        />

                        <FormControl>
                            <FormLabel>Sprache</FormLabel>
                            <RadioGroup row
                                value={enquiry.lang}
                                onChange={(e) => setEnquiry({...enquiry, lang:e.target.value})}
                            >
                                <FormControlLabel key="de" value="de" control={<Radio />} label="deutsch" />
                                <FormControlLabel key="en" value="en" control={<Radio />} label="englisch" />
                            </RadioGroup>
                        </FormControl>


                        <Stack direction="row" spacing={2}>
                            <Button variant="outlined" onClick={abort}>Abbrechen</Button>
                            <Button variant="contained" onClick={save} disabled={isProcessing || !enquiry.first_name || !enquiry.last_name || !enquiry.phone || !enquiry.email}>Speichern</Button>
                        </Stack>

                    </Stack>
                </Paper>
                ): null}
            </Stack>
        </Modal>
    </>
  )
}

export default AddEnquiry
import { Stack } from '@mui/material'
import React from 'react'
import Emails from '../components/Emails'

const EmailsPage = () => {
  return (
    <Stack direction="row">
      <Emails />
    </Stack>
  )
}

export default EmailsPage
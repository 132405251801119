import { Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Paper, Select, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import useApi from '../hooks/useApi'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const Emails = () => {
  
  const api = useApi();
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  
  useEffect(() => {
    getEmails()
  }, [])

  const getEmails = async() => {
    const data = await api.getEmails();
    if(data) {
        setEmails(data.results);
    }
  }

  const add = () => {
    setEmail({
        name:'',
        subject: '',
        subject_en: '',
        message: '',
        message_en: '',
    });
  }

  const edit = (item) => {
    setEmail(item);
  }


  const save = async() => {
    setIsProcessing(true);
    const data = email.id ? await api.updateEmail(email) : await api.addEmail(email);
    if(data) {
        setEmail(null);
        getEmails();
    }
    setIsProcessing(false);
  }

  const remove = async() => {
    setIsProcessing(true);
    const data = await api.removeEmail(email.id);
    if(data) {
        setEmail(null);
        getEmails();
    }
    setIsProcessing(false);
  }

  const abort = () => {
    setEmail(null);
  }

  return (
    <>
    <Paper className="spacing-2">
        <Stack spacing={2}>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                <Typography variant="h2">E-Mails</Typography>
                <Button variant="outlined" onClick={add}>Hinzufügen</Button>
            </Stack>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography variant="caption">ID</Typography></TableCell>
                            <TableCell><Typography variant="caption">Name</Typography></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {emails.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>{item.id}</TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell sx={{textAlign:'right'}}>
                                <IconButton onClick={() => edit(item)}>
                                    <EditOutlinedIcon fontSize="small" />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    </Paper>
    <Modal open={email !== null} onClose={abort}>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{minHeight:'100%'}}>
            {email !== null ? (
            <Paper className="spacing-2">
                <Stack spacing={2}>
                    <Typography variant="h2">E-Mail</Typography>

                    <TextField
                        size="small"
                        label="Bezeichnung"
                        value={email.name}
                        onChange={(e) => setEmail({...email, name:e.target.value})}
                    />

                    <Stack direction="row" spacing={2}>
                      <Stack spacing={2} sx={{width:'600px'}}>
                        <TextField multiline maxRows={2}
                          size="small"
                          label="Betreff"
                          value={email.subject}
                          onChange={(e) => setEmail({...email, subject:e.target.value})}
                        />
                        <TextField multiline maxRows={15}
                          size="small"
                          label="Nachricht"
                          value={email.message}
                          onChange={(e) => setEmail({...email, message:e.target.value})}
                        />
                      </Stack>

                      <Stack spacing={2} sx={{width:'600px'}}>
                        <TextField multiline maxRows={2}
                          size="small"
                          label="Betreff (EN)"
                          value={email.subject_en}
                          onChange={(e) => setEmail({...email, subject_en:e.target.value})}
                        />
                        <TextField multiline maxRows={15}
                          size="small"
                          label="Nachricht (EN)"
                          value={email.message_en}
                          onChange={(e) => setEmail({...email, message_en:e.target.value})}
                        />
                      </Stack>

                    </Stack>
                    
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" onClick={abort}>Abbrechen</Button>
                        <Button variant="contained" onClick={save} disabled={isProcessing || !email.name || !email.subject || !email.subject_en || !email.message || !email.message_en}>Speichern</Button>
                    </Stack>

                </Stack>
            </Paper>
            ): null}
        </Stack>
    </Modal>
    </>
  )
}

export default Emails
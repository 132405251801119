import { Box, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material'
import React, { useContext } from 'react'
import MainContext from '../contexts/MainContext'

const SetCurrency = () => {
  
    const {currencies, currency, setCurrency} = useContext(MainContext);

    const setItemById = (id) => {
        const items = currencies.filter((item) => item.id === id);
        if(items.length) {
            setCurrency(items[0]);
        }
    } 
  
  return (
    <Box>
        <Select size="small" autoWidth
            value={currency ? currency.id : ''}
            onChange={(e) => setItemById(parseInt(e.target.value))}
            >
            {currencies.map((item) => (
                <MenuItem key={item.id} value={item.id}>{item.code}</MenuItem>
            ))}
        </Select>
    </Box>
  )
}

export default SetCurrency
import { Box, Button, Chip, Grid, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import React from 'react'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import EnquiryStatuses from './EnquiryStatuses';
import EnquiryResult from './EnquiryResult';
import EnquiryParameters from './EnquiryParameters';
import EnquiryBooking from './EnquiryBooking';

const Enquiry = ({enquiry, setEnquiry, getEnquiries}) => {
  return (
    <Box sx={{position:'absolute', top:0, left:0, height:'100%', width:'100%', overflowY:'auto'}}>
      <Stack spacing={2} sx={{padding:'15px'}}>
          
          <Box>
            <Button variant="outlined" onClick={() => setEnquiry(null)}>Zur ÜBersicht</Button>
          </Box>

          <EnquiryParameters enquiry={enquiry} />

          <Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <EnquiryResult calculation={enquiry.calculation} />
              </Grid>
              <Grid item xs={6}>
                <EnquiryStatuses enquiry={enquiry} setEnquiry={setEnquiry} getEnquiries={getEnquiries} />
              </Grid>
              {enquiry.booking ? (
              <Grid item xs={12}>
                <EnquiryBooking booking={enquiry.booking} />
              </Grid>
              ) : null}
            </Grid>
          </Box>

      </Stack>

    </Box>
  )
}

export default Enquiry
import { Button, InputAdornment, Paper, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import '../Prototypes';
import useApi from '../hooks/useApi';

const Periods = () => {
  
  const api = useApi();
  const [periods, setPeriods] = useState({
    next_relocation: '', 
    next_virtual_tour: '',
    next_personal_tour: ''
  });
  const [isProcessing, setIsProcessing] = useState(false);
  
  useEffect(() => {
    getPeriods();
  }, []);

  const getPeriods = async() => {
    const data = await api.getPeriods();
    if(data) {
      setPeriods(data.result);
    }
  }

  const save = async() => {
    setIsProcessing(true);
    await api.updatePeriods(periods);
    setIsProcessing(false);
  }
  
  return (
    <Paper className="spacing-2" sx={{height:'100%'}}>
        <Stack spacing={2} alignItems="flex-start">
          <Typography variant="h2">Nächste Termine</Typography>
          <TextField 
            size="small" fullWidth
            label="Umzug"
            InputProps={{
              endAdornment: <InputAdornment position="end">Tage</InputAdornment>,
            }}
            value={periods.next_relocation}
            onChange={(e) => setPeriods({...periods, next_relocation: e.target.value.toIntString()})}
          />
          <TextField 
            size="small" fullWidth
            label="Virtuelle Besichtigung"
            InputProps={{
              endAdornment: <InputAdornment position="end">Tage</InputAdornment>,
            }}
            value={periods.next_virtual_tour.toCommaString()}
            onChange={(e) => setPeriods({...periods, next_virtual_tour: e.target.value.toIntString()})}
          />
          <TextField 
            size="small" fullWidth
            label="Persönliche Begehung"
            InputProps={{
              endAdornment: <InputAdornment position="end">Tage</InputAdornment>,
            }}
            value={periods.next_personal_tour}
            onChange={(e) => setPeriods({...periods, next_personal_tour: e.target.value.toIntString()})}
          />
          <Stack direction="row" justifyContent="flex-end" sx={{width:'100%'}}>
            <Button variant="contained" disabled={isProcessing || !periods.next_relocation || !periods.next_virtual_tour || !periods.next_personal_tour} onClick={save}>Speichern</Button>
          </Stack>
        </Stack>
    </Paper>
  )
}

export default Periods
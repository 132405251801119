import { Box, Tooltip } from '@mui/material'
import React from 'react'

const StatusIcon = ({status_name, status_color, parent_status_name, parent_status_color}) => {
  
    const getColor = () => {
        return parent_status_color ? parent_status_color : status_color;
    }

    const getTooltip = () => {
      return parent_status_name ? parent_status_name + ' | ' + status_name  : status_name;
    }
  
    return (
    <Tooltip title={getTooltip()}>
      <Box sx={{width:'24px', height:'24px', backgroundColor:getColor(), borderRadius:'100%'}}></Box>
    </Tooltip>
    
  )
}

export default StatusIcon
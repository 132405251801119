import { Box, Grid, Paper, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'

const EnquiryBooking = ({booking}) => {
  return (
    <Paper className="spacing-2">
        <Stack spacing={2}>
            <Typography variant="h2">Buchung</Typography>
            <Typography>vom {dayjs(booking.created_at).format('DD.MM.YYYY')} um {dayjs(booking.created_at).format('HH:mm')} Uhr</Typography>
            <Box>
                <Grid container>
                    <Grid item xs={4}>
                        <Stack spacing={2}>
                            <Typography variant="h3">Auftraggeber</Typography>
                            <Stack>
                                <Box>{booking.first_name} {booking.last_name}</Box>
                                {booking.company_name ? <Box>{booking.company_name}</Box> : null}
                                <Box>{booking.street} {booking.street_no}</Box>
                                <Box>{booking.postal_code} {booking.city}</Box>
                                <Box>{booking.country.name}</Box>
                                
                            </Stack>
                            <Stack>
                                <Box><a href={'tel:'+booking.phone.toPhoneLink()}>{booking.phone}</a></Box>
                                <Box><a href={'mailto:'+booking.email}>{booking.email}</a></Box>
                            </Stack>
                            
                        </Stack>
                    </Grid>
                    <Grid item xs={4}>
                        <Stack spacing={2}>
                            <Typography variant="h3">Abholadresse</Typography>
                            <Stack>
                                <Box>{booking.pickup_first_name} {booking.pickup_last_name}</Box>
                                {booking.pickup_company_name ? <Box>{booking.pickup_company_name}</Box> : null}
                                <Box>{booking.pickup_street} {booking.pickup_street_no}</Box>
                                <Box>{booking.pickup_postal_code} {booking.pickup_city}</Box>
                                <Box>{booking.pickup_country.name}</Box>
                            </Stack>
                            <Stack>
                                <Box>Etage: {booking.pickup_floor}</Box>
                                <Box>Aufzug: {booking.pickup_elevator ? 'ja' : 'nein'}</Box>
                            </Stack>
                            <Stack>
                                <Box><a href={'tel:' + booking.pickup_phone}>{booking.pickup_phone}</a></Box>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={4}>
                        <Stack spacing={2}>
                            <Typography variant="h3">Zustelladresse</Typography>
                            <Stack>
                                <Box>{booking.delivery_first_name} {booking.delivery_last_name}</Box>
                                {booking.delivery_company_name ? <Box>{booking.delivery_company_name}</Box> : null}
                                <Box>{booking.delivery_street} {booking.delivery_street_no}</Box>
                                <Box>{booking.delivery_postal_code} {booking.delivery_city}</Box>
                                <Box>{booking.delivery_country.name}</Box>
                            </Stack>
                            <Stack>
                                <Box>Etage: {booking.delivery_floor}</Box>
                                <Box>Aufzug: {booking.delivery_elevator ? 'ja' : 'nein'}</Box>
                            </Stack>
                            <Stack>
                            <Box><a href={'tel:' + booking.delivery_phone}>{booking.delivery_phone}</a></Box>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Stack>
    </Paper>
  )
}

export default EnquiryBooking
import { Button, InputAdornment, Paper, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import '../Prototypes';
import useApi from '../hooks/useApi';

const TransportParameter = () => {
  
  const api = useApi();
  const [transportParameter, setTransportParameter] = useState({
    min_distance: '', 
    min_volume: '',
    max_volume: '',
    max_weight: '',
    max_item_length: '',
    max_item_width: '',
    max_item_height: '',
    max_item_weight: '',
    insurance_coverage: '',
    margin: ''
  });
  const [isProcessing, setIsProcessing] = useState(false);
  
  useEffect(() => {
    getTransportParameter();
  }, []);

  const getTransportParameter = async() => {
    const data = await api.getTransportParameter();
    if(data) {
      setTransportParameter(data.result);
    }
  }

  const save = async() => {
    setIsProcessing(true);
    await api.updateTransportParameter(transportParameter);
    setIsProcessing(false);
  }
  
  return (
    <Paper className="spacing-2" sx={{height:'100%'}}>
        <Stack spacing={2} alignItems="flex-start">
          <Typography variant="h2">Allgemein</Typography>
          <TextField 
            size="small" fullWidth
            label="Min. Distanz"
            InputProps={{
              endAdornment: <InputAdornment position="end">km</InputAdornment>,
            }}
            value={transportParameter.min_distance}
            onChange={(e) => setTransportParameter({...transportParameter, min_distance: e.target.value.toIntString()})}
          />
          <TextField 
            size="small" fullWidth
            label="Min. Volumen"
            InputProps={{
              endAdornment: <InputAdornment position="end">m³</InputAdornment>,
            }}
            value={transportParameter.min_volume.toCommaString()}
            onChange={(e) => setTransportParameter({...transportParameter, min_volume: e.target.value.toFloatString()})}
          />
          <TextField 
            size="small" fullWidth
            label="Max. Volumen"
            InputProps={{
              endAdornment: <InputAdornment position="end">m³</InputAdornment>,
            }}
            value={transportParameter.max_volume}
            onChange={(e) => setTransportParameter({...transportParameter, max_volume: e.target.value.toIntString()})}
          />
          <TextField 
            size="small" fullWidth
            label="Max. Gewicht"
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
            value={transportParameter.max_weight}
            onChange={(e) => setTransportParameter({...transportParameter, max_weight: e.target.value.toIntString()})}
          />
          <Stack direction="row" spacing={1}>
            <TextField 
              size="small" fullWidth
              label="Max. Länge"
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
              value={transportParameter.max_item_length}
              onChange={(e) => setTransportParameter({...transportParameter, max_item_length: e.target.value.toIntString()})}
            />
            <TextField 
              size="small" fullWidth
              label="Max. Breite"
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
              value={transportParameter.max_item_width}
              onChange={(e) => setTransportParameter({...transportParameter, max_item_width: e.target.value.toIntString()})}
            />
            <TextField 
              size="small" fullWidth
              label="Max. Höhe"
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
              value={transportParameter.max_item_height}
              onChange={(e) => setTransportParameter({...transportParameter, max_item_height: e.target.value.toIntString()})}
            />
          </Stack>
          <TextField 
              size="small" fullWidth
              label="Max. Einzelgewicht"
              InputProps={{
                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
              }}
              value={transportParameter.max_item_weight}
              onChange={(e) => setTransportParameter({...transportParameter, max_item_weight: e.target.value.toIntString()})}
            />
          <TextField 
            size="small" fullWidth
            label="Versicherungssumme"
            InputProps={{
              endAdornment: <InputAdornment position="end">EUR / m³</InputAdornment>,
            }}
            value={transportParameter.insurance_coverage.toCommaString()}
            onChange={(e) => setTransportParameter({...transportParameter, insurance_coverage: e.target.value.toFloatString()})}
          />
          <TextField 
            size="small" fullWidth
            label="Marge"
            value={transportParameter.margin.toCommaString()}
            onChange={(e) => setTransportParameter({...transportParameter, margin: e.target.value.toFloatString()})}
          />
          <Stack direction="row" justifyContent="flex-end" sx={{width:'100%'}}>
            <Button variant="contained" disabled={isProcessing || !transportParameter.min_distance || !transportParameter.max_volume || !transportParameter.margin} onClick={save}>Speichern</Button>
          </Stack>
        </Stack>
    </Paper>
  )
}

export default TransportParameter
import { Autocomplete, Button, IconButton, Modal, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import '../Prototypes';
import useApi from '../hooks/useApi';
import MainContext from '../contexts/MainContext';
import Search from './Search';

const Airports = () => {
    
    const {countries} = useContext(MainContext);
    const api = useApi();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [airports, setAirports] = useState([]);
    const [airport, setAirport] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [search, setSearch] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
  
    useEffect(() => {
      setFilteredItems(airports.filter(item => (
          search === '' ||
          item.country.name.toLowerCase().includes(search.toLowerCase()) ||
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.iata.toLowerCase().includes(search.toLowerCase()) 
        )));
        setPage(0);
    }, [airports, search])

    useEffect(() => {
        getAirports();
    }, []);

    const getAirports = async() => {
      const data = await api.getAirports();
      if(data) {
          setAirports(data.results);
      }
    }

    const onPageChange = (event, newPage) => {
        setPage(newPage);
    };

    const getCountryById = (id) => {
        const items = countries.filter((item) => item.id === id);
        return items.length ? items[0] : null; 
    }

    const add = () => {
        setAirport({
        country_id: '',
        name: '',
        unlocode: '',
        lat: '',
        lng: ''
        })
    }

    const edit = (item) => {
        setAirport(item);
    }

    const save = async() => {
        setIsProcessing(true);
        const data = airport.id ? await api.updateAirport(airport) : await api.addAirport(airport);
        if(data) {
            setAirport(null);
            getAirports();
        }
        setIsProcessing(false);
    }

    const remove = async() => {
        setIsProcessing(true);
        const data = await api.removeAirport(airport.id);
        if(data) {
          setAirport(null);
          getAirports();
        }
        setIsProcessing(false);
    }

    const abort = () => {
        setAirport(null);
    }
  
  return (
    <>
      <Paper className="spacing-2" sx={{height:'100%'}}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Typography variant="h2">Flughäfen</Typography>
              <Button variant="outlined" onClick={add}>Hinzufügen</Button>
          </Stack>
          <Search search={search} setSearch={setSearch} />
          <TableContainer >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><Typography variant="caption">Land</Typography></TableCell>
                  <TableCell><Typography variant="caption">Name</Typography></TableCell>
                  <TableCell><Typography variant="caption">IATA</Typography></TableCell>
                  <TableCell><Typography variant="caption">Lat</Typography></TableCell>
                  <TableCell><Typography variant="caption">Lng</Typography></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.country.name}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.iata}</TableCell>
                  <TableCell>{item.lat}</TableCell>
                  <TableCell>{item.lng}</TableCell>
                  <TableCell sx={{textAlign:'right'}}>
                    <IconButton onClick={() => edit(item)}>
                      <EditOutlinedIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination 
                    count={filteredItems.length} 
                    page={page} 
                    rowsPerPage={rowsPerPage} 
                    rowsPerPageOptions={[]}
                    onPageChange={onPageChange}
                  />
                </TableRow>
            </TableFooter>
            </Table>
          </TableContainer>
        </Stack>
      </Paper>
      <Modal open={airport !== null} onClose={abort}>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{minHeight:'100%'}}>
        {airport !== null ? (
        <Paper className="spacing-2">
          <Stack spacing={2}>
            <Typography variant="h2">Flughafen</Typography>
            <Autocomplete size="small"
              options={countries}
              value={getCountryById(airport.country_id)}
              onChange={(e, value) => setAirport({...airport, country_id: value ? value.id : null})}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Land" />}
            /> 
            <TextField
              size="small"
              label="Name"
              value={airport.name}
              onChange={(e) => setAirport({...airport, name:e.target.value})}
            />
            <TextField
              size="small"
              label="IATA"
              value={airport.iata}
              onChange={(e) => setAirport({...airport, iata:e.target.value})}
            />
            <Stack direction="row" spacing={2}>
              <TextField
                size="small"
                label="Lat"
                value={airport.lat}
                onChange={(e) => setAirport({...airport, lat:e.target.value.toLatLong()})}
              />
              <TextField
                size="small"
                label="Lng"
                value={airport.lng}
                onChange={(e) => setAirport({...airport, lng:e.target.value.toLatLong()})}
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              {airport.id ? (
              <Button variant="outlined" color="error" onClick={remove} disabled={isProcessing}>Löschen</Button>
              ) : null}
              <Button variant="outlined" onClick={abort}>Abbrechen</Button>
              <Button variant="contained" onClick={save} disabled={isProcessing}>Speichern</Button>
            </Stack>
          </Stack>
        </Paper>
        ): null}
        </Stack>
      </Modal>
    </>
  )
}

export default Airports
import React from 'react'
import Countries from '../components/Countries'
import { Grid, Stack } from '@mui/material'
import Currencies from '../components/Currencies'
import Statuses from '../components/Statuses'
import Articles from '../components/Articles'
import Periods from '../components/Periods'

const GlobalParametersPage = () => {
    
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Countries />
      </Grid>
      <Grid item xs={4}>
          <Stack sx={{height:'100%'}} spacing={2}>
            <Currencies />
            <Periods />
          </Stack>
      </Grid>
      <Grid item xs={4}>
        <Statuses />
      </Grid>
      <Grid item xs={8}>
        <Articles />
      </Grid>
    </Grid>
  )
}

export default GlobalParametersPage
import { Button, IconButton, InputAdornment, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, {  useState } from 'react'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import '../Prototypes';
import useApi from '../hooks/useApi';

const InsuranceClasses = ({freightTypeId, insuranceClasses, getInsuranceClasses, getCountryParmeters}) => {
  
	const api = useApi();
	const [insuranceClass, setInsuranceClass] =  useState(null);
	const [isProcessing, setIsProcessing] =  useState(false);

	const add = () => {
		setInsuranceClass({
			freight_type_id: freightTypeId,
			class: '',
			name: '',
			premium: '',
			min_rate: ''
		});
	}

	const edit = (item) => {
		setInsuranceClass({
			id: item.id,
			class: item.class,
			name: item.name,
			premium: item.premium,
			min_rate: item.min_rate
		});
	}

	const save = async() => {
		setIsProcessing(true);
		const data = insuranceClass.id ? await api.updateInsuranceClass(insuranceClass) : await api.addInsuranceClass(insuranceClass);
		if(data) {
			setInsuranceClass(null);
			getInsuranceClasses();
			getCountryParmeters();
		}
		setIsProcessing(false);
	}

	const remove = async() => {
		setIsProcessing(true);
		const data = await api.removeInsuranceClass(insuranceClass.id);
		if(data) {
			setInsuranceClass(null);
			getInsuranceClasses();
		}
		setIsProcessing(false);
	}

	const abort = () => {
		setInsuranceClass(null)
	}
	
	return (
    <>
			<Paper className="spacing-2" sx={{height:'100%'}}>
				<Stack spacing={2}>
					<Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
						<Typography variant="h2">Versicherungsklassen</Typography>
						<Button variant="outlined" onClick={add}>Hinzufügen</Button>
					</Stack>
					<TableContainer>
						<Table size="small">
							<TableHead>
								<TableRow sx={{verticalAlign:'top'}}>
									<TableCell><Typography variant="caption">Klasse</Typography></TableCell>
									<TableCell><Typography variant="caption">Bezeichnung</Typography></TableCell>
									<TableCell><Typography variant="caption">Prämie<br/>‰</Typography></TableCell>
									<TableCell><Typography variant="caption">Min. Kosten<br/>EUR</Typography></TableCell>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{insuranceClasses.map((item) => (
								<TableRow key={item.id}>
									<TableCell>{item.class}</TableCell>
									<TableCell>{item.name}</TableCell>
									<TableCell>{item.premium.toCommaString()}</TableCell>
									<TableCell>{item.min_rate.toCommaString()}</TableCell>
									<TableCell sx={{textAlign:'right'}}>
										<IconButton onClick={() => edit(item)}>
												<EditOutlinedIcon fontSize="small" />
										</IconButton>
									</TableCell>
								</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Stack>
			</Paper>
			<Modal open={insuranceClass !== null} onClose={abort}>
					<Stack direction="row" alignItems="center" justifyContent="center" sx={{minHeight:'100%'}}>
					{insuranceClass !== null ? (
					<Paper className="spacing-2">
						<Stack spacing={2}>
							<Typography variant="h2">Versicherungsklasse</Typography>
							<TextField 
								size="small" fullWidth
								label="Klasse"
								value={insuranceClass.class}
								onChange={(e) => setInsuranceClass({...insuranceClass, class: e.target.value})}
							/>
							<TextField 
								size="small" fullWidth
								label="Bezeichnung"
								value={insuranceClass.name}
								onChange={(e) => setInsuranceClass({...insuranceClass, name: e.target.value})}
							/>
							<TextField 
								size="small" fullWidth
								label="Prämie"
								InputProps={{
									endAdornment: <InputAdornment position="end">‰</InputAdornment>,
								}}
								value={insuranceClass.premium.toCommaString()}
								onChange={(e) => setInsuranceClass({...insuranceClass, premium: e.target.value.toFloatString()})}
							/>
							<TextField 
								size="small" fullWidth
								label="Min. Kosten"
								InputProps={{
									endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
								}}
								value={insuranceClass.min_rate.toCommaString()}
								onChange={(e) => setInsuranceClass({...insuranceClass, min_rate: e.target.value.toFloatString()})}
							/>
							<Stack direction="row" spacing={2}>
									{insuranceClass.id ? (
									<Button variant="outlined" color="error" onClick={remove} disabled={isProcessing}>Löschen</Button>
									) : null}
									<Button variant="outlined" onClick={abort}>Abbrechen</Button>
									<Button variant="contained" onClick={save} disabled={isProcessing || !insuranceClass.name || !insuranceClass.premium || !insuranceClass.min_rate}>Speichern</Button>
							</Stack>
						</Stack>
					</Paper>
					) : null}
					</Stack>
			</Modal>
		</>
  )
}

export default InsuranceClasses
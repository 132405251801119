import { Box, Divider, FormControlLabel, Grid, Radio, RadioGroup, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import React, { useContext, useState } from 'react'
import MainContext from '../contexts/MainContext';
import AddEnquiry from './AddEnquiry';
import CalculationArticles from './CalculationArticles';
import SetCurrency from './SetCurrency';
import useApi from '../hooks/useApi';

const Calculation = ({calculation, setCalculation}) => {
  
    const {currency} = useContext(MainContext);
    const api = useApi();
    const [isProcessing, setIsProcessing] = useState(false);

    const setRoute = async(route) => {
        setIsProcessing(true);
        const data = await api.setCalculationRoute({id: calculation.id, route:route});
        if(data) {
          setCalculation(data.result);
        }
        setIsProcessing(false);
    }

return (
    <Stack spacing={2}>

        {calculation.result.main_services.routes.length > 1 ? (
        <Stack direction="row" justifyContent={calculation.result.main_services.routes.length > 1 ? "space-between" : "flex-end"}>
            <RadioGroup row
                value={calculation.route}
                onChange={e => setRoute(e.target.value)}
            >
                {calculation.result.main_services.routes.map((item, index) => (
                <FormControlLabel key={index} value={index} control={<Radio disabled={isProcessing} />} label={item.pre_run.destination.name + ' ➤ ' + item.post_run.origin.name} />
                ))}
            </RadioGroup>
        </Stack>
         ) : null}

        <Divider></Divider>

        <CalculationArticles calculation={calculation} startExpanded={true} />

        <Box>
            <AddEnquiry calculation={calculation} currency={currency} />
        </Box>
    </Stack>
  )
}

export default Calculation
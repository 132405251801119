import { Autocomplete, Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, {  useContext, useEffect, useState } from 'react'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import useApi from '../hooks/useApi';
import MainContext from '../contexts/MainContext';
import Search from './Search';

const SeaCountryParameters = ({containers, seaCountryParameters, getSeaCountryParameters, insuranceClasses, customsClasses}) => {
  
  const {countries} = useContext(MainContext);
  const api = useApi();
  const [seaCountryParameter, setSeaCountryParameter] = useState(null);                         
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isProcessing, setIsProcessing] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    setFilteredItems(seaCountryParameters.filter(parameter => (search === '' || parameter.country.name.toLowerCase().includes(search.toLowerCase()))));
    setPage(0);
  }, [seaCountryParameters, search])

  const getContainerRate = (parameter, containerId, type) => {
    if(parameter.container_rates) {
      const containerRate = parameter.container_rates.find(obj => {
          return obj.container_id === containerId;
      });
      return containerRate && containerRate[type] ? containerRate[type] : '';
    }
    return '';
  }

  const setContainerRate = (containerId, type, rate) => {
    var updated = false;
    var containerRates = seaCountryParameter.container_rates.map(containerRate => {
      if (containerRate.container_id === containerId) {
        updated = true;
        return {...containerRate, [type]:rate.toFloatString()};
      }
      else {
        return containerRate;
      }
    });
    if(!updated) {
      containerRates.push({container_id:containerId, [type]:rate.toFloatString()})
    }
    setSeaCountryParameter({...seaCountryParameter, container_rates:containerRates})
  }

  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const getCountryById = (id) => {
    const items = countries.filter((item) => item.id === id);
    return items.length ? items[0] : null; 
  }

  const isFormDisabled = (parameter, containers) => {
    if(!parameter.id && !parameter.country_id) return true;
    if(!parameter.loading_rate || !parameter.unloading_rate) return true;
    if(parameter.container_rates.length < containers.length) return true;
    for(let i = 0; i < parameter.container_rates.length; i++) {
      if(!parameter.container_rates[i].transport_rate || !parameter.container_rates[i].thc_rate) return true;
    }
  }

  const add = () => {
    setSeaCountryParameter({
      country_id: '',
      insurance_class_id : '',
      customs_class_id : '',
      loading_rate: '',
      unloading_rate: '',
      container_rates: []
    });
  }

  const edit = (parameter) => {
    setSeaCountryParameter({
      id: parameter.id,
      _country_id : parameter.country_id,
      insurance_class_id : parameter.insurance_class_id,
      customs_class_id : parameter.customs_class_id,
      loading_rate: parameter.loading_rate,
      unloading_rate: parameter.unloading_rate,
      container_rates : parameter.container_rates
    }); 
  }

  const save = async() => {
    setIsProcessing(true);
    const data = seaCountryParameter.id ? await api.updateSeaCountryParameter(seaCountryParameter) : await api.addSeaCountryParameter(seaCountryParameter);
    if(data) {
      setSeaCountryParameter(null);
      getSeaCountryParameters();
    }
    setIsProcessing(false);
  }

  const remove = async() => {
    setIsProcessing(true);
    const data = api.removeRoadCountryParameter(seaCountryParameter.id);
    if(data) {
      setSeaCountryParameter(null);
      getSeaCountryParameters();
    }
    setIsProcessing(false);
  }

  const abort = () => {
    setSeaCountryParameter(null);
  }

  return (
    <>
      <Paper className="spacing-2">
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
            <Typography variant="h2">Kosten je Land</Typography>
            <Button variant="outlined" onClick={add}>Hinzufügen</Button>
          </Stack>
          <Search search={search} setSearch={setSearch} />
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={5}></TableCell>
                  <TableCell colSpan={containers.length}><Typography variant="overline">Transportkosten</Typography></TableCell>
                  <TableCell colSpan={containers.length}><Typography variant="overline">Terminal Handling</Typography></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow sx={{verticalAlign:'top'}}>
                  <TableCell sx={{width:'200px'}}><Typography variant="caption">Land</Typography></TableCell>
                  <TableCell><Typography variant="caption">Beladen<br />EUR / m³</Typography></TableCell>
                  <TableCell><Typography variant="caption">Entladen<br />EUR / m³</Typography></TableCell>
                  <TableCell><Typography variant="caption">Versicherung</Typography></TableCell>
                  <TableCell><Typography variant="caption">Zoll</Typography></TableCell>
                  {containers.map((container) => (
                    <TableCell key={container.id}><Typography variant="caption">{container.name}<br />EUR / km</Typography></TableCell>
                  ))}
                  {containers.map((container) => (
                    <TableCell key={container.id}><Typography variant="caption">{container.name}<br />EUR</Typography></TableCell>
                  ))}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((parameter) => {
                return (
                  <TableRow key={parameter.id}>
                    <TableCell>{parameter.country.name}</TableCell>
                    <TableCell>{parameter.loading_rate.toCommaString()}</TableCell>
                    <TableCell>{parameter.unloading_rate.toCommaString()}</TableCell>
                    <TableCell>{parameter.insurance_class.name}</TableCell>
                    <TableCell>{parameter.customs_class.name}</TableCell>
                    {containers.map((container) => (
                    <TableCell key={container.id}>{getContainerRate(parameter, container.id, 'transport_rate').toCommaString()}</TableCell>
                    ))}
                    {containers.map((container) => (
                    <TableCell key={container.id}>{getContainerRate(parameter, container.id, 'thc_rate').toCommaString()}</TableCell>
                    ))}
                    <TableCell sx={{textAlign:'right'}}>
                      <IconButton onClick={() => edit(parameter)}>
                        <EditOutlinedIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
              </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination 
                      count={filteredItems.length} 
                      page={page} 
                      rowsPerPage={rowsPerPage} 
                      rowsPerPageOptions={[]}
                      onPageChange={onPageChange}
                    />
                  </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Stack>
      </Paper>
      <Modal open={seaCountryParameter !== null} onClose={abort}>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{minHeight:'100%'}}>
          {seaCountryParameter !== null ? (
          <Paper className="spacing-2">
            <Stack spacing={2}>
              <Typography variant="h2">Kosten</Typography>
              <Autocomplete size="small"
                  options={countries}
                  value={getCountryById(seaCountryParameter.id ? seaCountryParameter._country_id : seaCountryParameter.country_id)}
                  onChange={(e, value) => setSeaCountryParameter({...seaCountryParameter, country_id: value ? value.id : null})}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} label="Land" />}
                  disabled={seaCountryParameter.id ? true : false}
              />
              <TextField
                size="small"
                label="Beladen"
                InputProps={{
                  endAdornment: <InputAdornment position="start">EUR / m³</InputAdornment>,
                }}
                value={seaCountryParameter.loading_rate.toCommaString()}
                onChange={(e) => setSeaCountryParameter({...seaCountryParameter, loading_rate:e.target.value.toFloatString()})}
              /> 
              <TextField size="small"
                label="Entladen"
                InputProps={{
                  endAdornment: <InputAdornment position="start">EUR / m³</InputAdornment>,
                }}
                value={seaCountryParameter.unloading_rate.toCommaString()}
                onChange={(e) => setSeaCountryParameter({...seaCountryParameter, unloading_rate:e.target.value.toFloatString()})}
              />
              <FormControl fullWidth>
                <InputLabel size="small">Versicherungsklasse</InputLabel>
                <Select size="small"
                    fullWidth
                    value={seaCountryParameter.insurance_class_id}
                    onChange={(e) => setSeaCountryParameter({...seaCountryParameter, insurance_class_id: e.target.value})}
                    label="Versicherungsklasse"
                    >
                    {insuranceClasses.map((item) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel size="small">Zollklasse</InputLabel>
                <Select size="small"
                    fullWidth
                    value={seaCountryParameter.customs_class_id}
                    onChange={(e) => setSeaCountryParameter({...seaCountryParameter, customs_class_id: e.target.value})}
                    label="Versicherungsklasse"
                    >
                    {customsClasses.map((item) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Stack direction="row" spacing={2}>
                <Stack spacing={2}>
                  <Typography variant="button">Transportkosten</Typography>
                  {containers.map((container) => (
                    <TextField key={container.id}
                      size="small"
                      label={container.name}
                      InputProps={{
                        endAdornment: <InputAdornment position="start">EUR / km</InputAdornment>,
                      }}
                      value={getContainerRate(seaCountryParameter, container.id, 'transport_rate').toCommaString()}
                      onChange={(e) => setContainerRate(container.id, 'transport_rate', e.target.value)}
                    />
                  ))}
                </Stack>
                <Stack spacing={2}>
                  <Typography variant="button">THC</Typography>
                  {containers.map((container) => (
                    <TextField key={container.id}
                      size="small"
                      label={container.name}
                      InputProps={{
                        endAdornment: <InputAdornment position="start">EUR</InputAdornment>,
                      }}
                      value={getContainerRate(seaCountryParameter, container.id, 'thc_rate').toCommaString()}
                      onChange={(e) => setContainerRate(container.id, 'thc_rate', e.target.value)}
                    />
                  ))}
                </Stack>
              </Stack>

              <Stack direction="row" spacing={2}>
                {seaCountryParameter.id ? (
                <Button variant="outlined" color="error" onClick={remove} disabled={isProcessing}>Löschen</Button>
                ) : null}
                <Button variant="outlined" onClick={abort}>Abbrechen</Button>
                <Button variant="contained" onClick={save} disabled={isProcessing ||isFormDisabled(seaCountryParameter, containers)}>Speichern</Button>
              </Stack>
            </Stack>
          </Paper>
          ): null}
        </Stack>
      </Modal>
    </>
  )
}

export default SeaCountryParameters
import { Stack } from '@mui/material'
import React from 'react'

const Page = ({children}) => {
  return (
    <Stack sx={{width:'100%', height:'100%'}}>
        {children}
    </Stack>
  )
}

export default Page
import React, { useContext, useEffect, useState } from 'react'
import useApi from '../hooks/useApi'
import { Box, Button, IconButton, Modal, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import MainContext from '../contexts/MainContext';

const Statuses = () => {
  
    const {statuses, getStatuses} = useContext(MainContext);
    const api = useApi();
    const [status, setStatus] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        getStatuses();
    }, []);

    const add = (statusId) => {
        setStatus({
          status_id: statusId,
          name: '',
          color: statusId ? null : '',
          sort: ''
        });
      }
    
    const edit = (item) => {
        setStatus(item);
    }
  
    const save = async() => {
        setIsProcessing(true);
        const data = status.id ? await api.updateStatus(status) : await api.addStatus(status);
        if(data) {
          setStatus(null);
          getStatuses();
        }
        setIsProcessing(false);
      }
    
      const remove = async() => {
        setIsProcessing(true);
        const data = await api.removeStatus(status.id);
        if(data) {
            setStatus(null);
            getStatuses();
        }
        setIsProcessing(false);
      }
    
      const abort = () => {
        setStatus(null);
      }

    return (
    <>
        <Paper className="spacing-2" sx={{height:'100%'}}>
            <Stack spacing={2}>
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                <Typography variant="h2">Status</Typography>
                <Button variant="outlined" onClick={() => add(null)}>Hinzufügen</Button>
                </Stack>
            </Stack>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography variant="caption">Status</Typography></TableCell>
                            <TableCell><Typography variant="caption">Farbe</Typography></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {statuses.map((item) => {
                            return (
                            <>
                            <TableRow key={item.id}>
                                <TableCell>{item.sort} | {item.name}</TableCell>
                                <TableCell>{item.color}</TableCell>
                                <TableCell sx={{textAlign:'right'}}>
                                    <Stack direction="row" justifyContent="flex-end">
                                        <IconButton onClick={() => add(item.id)}>
                                            <AddCircleOutlineOutlinedIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton onClick={() => edit(item)}>
                                            <EditOutlinedIcon fontSize="small" />
                                        </IconButton>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                            {item.statuses.map((subitem) => (
                            <TableRow key={subitem.id}>
                                <TableCell><Box sx={{marginLeft:'20px'}}>{subitem.sort} | {subitem.name}</Box></TableCell>
                                <TableCell></TableCell>
                                <TableCell sx={{textAlign:'right'}}>
                                    <IconButton onClick={() => edit(subitem)}>
                                        <EditOutlinedIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                            ))}
                            </>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
        <Modal open={status !== null} onClose={abort}>
            <Stack direction="row" alignItems="center" justifyContent="center" sx={{minHeight:'100%'}}>
                {status !== null ? (
                <Paper className="spacing-2">
                    <Stack spacing={2}>
                        <Typography variant="h2">Status</Typography>
                        <TextField
                            size="small"
                            label="Bezeichnung"
                            value={status.name}
                            onChange={(e) => setStatus({...status, name:e.target.value})}
                        />
                        {status.color !== null ? (
                        <TextField
                            size="small"
                            label="Farbe"
                            value={status.color}
                            onChange={(e) => setStatus({...status, color:e.target.value})}
                        />
                        ):null}
                        <TextField
                            size="small"
                            label="Reihenfolge"
                            value={status.sort}
                            onChange={(e) => setStatus({...status, sort:e.target.value})}
                        />
                        <Stack direction="row" spacing={2}>
                            {status.id ? (
                            <Button variant="outlined" color="error" onClick={remove} disabled={isProcessing}>Löschen</Button>
                            ) : null}
                            <Button variant="outlined" onClick={abort}>Abbrechen</Button>
                            <Button variant="contained" onClick={save} disabled={isProcessing || !status.name || (!status.color && !status.status_id) || !status.sort}>Speichern</Button>
                        </Stack>
                    </Stack>
                </Paper>
                ): null}
            </Stack>
        </Modal>
    </>
  )
}

export default Statuses
import { Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import ContainerTypes from '../components/ContainerTypes'
import SeaParameter from '../components/SeaParameter'
import Containers from '../components/Containers'
import SeaCountryParameters from '../components/SeaCountryParameters'
import InsuranceClasses from '../components/InsuranceClasses'
import SeaProfits from '../components/SeaProfits'
import CustomsClasses from '../components/CustomsClasses'
import useApi from '../hooks/useApi'
import { Box, Grid } from '@mui/material'
import Seaports from '../components/Seaports'
import SeasonSurcharges from '../components/SeasonSurcharges'

const SeaParametersPage = ({containers, getContainers}) => {

  const freightTypeId = 2;
  const api = useApi();
  const [containerTypes, setContainerTypes] = useState([]);
  const [insuranceClasses, setInsuranceClasses] = useState([]);
  const [customsClasses, setCustomsClasses] = useState([]);
  const [seaCountryParameters, setSeaCountryParameters] = useState([]); 

  useEffect(() => {
    getContainerTypes();
    getInsuranceClasses();
    getCustomsClasses();
    getSeaCountryParameters();
  }, [])

  const getContainerTypes = async() => {
    const data = await api.getContainerTypes();
    if(data) {
      setContainerTypes(data.results);
    }
  }
  
  const getInsuranceClasses = async() => {
    const data = await api.getInsuranceClassesByFreightTypeId(freightTypeId);
    if(data) {
      setInsuranceClasses(data.results);
    }
	}

  const getCustomsClasses = async() => {
    const data = await api.getCustomsClassesByFreightTypeId(freightTypeId);
    if(data) {
      setCustomsClasses(data.results);
    }
	}

  const getSeaCountryParameters = async() => {
    const data = await api.getSeaCountryParameters();
    if(data) {
      setSeaCountryParameters(data.results);
    }
  }

  return (
    
    <Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SeaCountryParameters seaCountryParameters={seaCountryParameters} getSeaCountryParameters={getSeaCountryParameters} containers={containers} insuranceClasses={insuranceClasses} customsClasses={customsClasses} />
        </Grid>
        <Grid item xs={8}>
          <Seaports containers={containers} />
        </Grid>
        <Grid item xs={4}>
          <SeaParameter />
        </Grid>
        <Grid item xs={4}>
          <InsuranceClasses freightTypeId={freightTypeId} insuranceClasses={insuranceClasses} getInsuranceClasses={getInsuranceClasses} getCountryParmeters={getSeaCountryParameters} />
        </Grid>
        <Grid item xs={4}>
          <CustomsClasses freightTypeId={freightTypeId} customsClasses={customsClasses} getCustomsClasses={getCustomsClasses} getCountryParmeters={getSeaCountryParameters} />
        </Grid>
        <Grid item xs={4}>
          <SeaProfits containerTypes={containerTypes} />
        </Grid>
        <Grid item xs={4}>
          <SeasonSurcharges freightTypeId={freightTypeId} />
        </Grid>
        <Grid item xs={4}>
          <Containers containers={containers} getContainers={getContainers} containerTypes={containerTypes} />
        </Grid>
        <Grid item xs={4}>
          <ContainerTypes containerTypes={containerTypes} getContainerTypes={getContainerTypes} getContainers={getContainers} />
        </Grid>
      </Grid>

    <Stack direction="row" spacing={2} alignItems="flex-start">
      <Stack spacing={2}>
        
        
        
        
        
        
        
      </Stack>
      
    </Stack>

    </Box>
    

  )
}

export default SeaParametersPage
import React from 'react'
import { Box, Chip, Divider, Grid, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from '@mui/material'
import dayjs from 'dayjs'

const EnquiryParameters = ({enquiry}) => {
  return (
    <Paper className="spacing-2">
        
        <Stack spacing={2}>
            <Box>
                <Grid container spacing={2}>
                    <Grid xs={3} item>
                        <Stack spacing={2}>
                            <Typography variant="h2">Referenznummer</Typography>
                            <Box sx={{fontSize:'1.5em'}}>{enquiry.reference_no}</Box>
                        </Stack>
                    </Grid>
                    <Grid xs={3} item>
                        <Stack spacing={2}>
                            <Typography variant="h2">Standorte</Typography>
                            <Stack spacing={1}>
                                <Stack spacing={1} direction="row" alignItems="center">
                                    <Tooltip title={enquiry.calculation.origin_country.name}>
                                        <Chip label={enquiry.calculation.origin_country.alpha2} variant="outlined" sx={{cursor:'pointer'}} />
                                    </Tooltip>
                                    <Box>{enquiry.calculation.origin_postal_code} {enquiry.calculation.origin_city}</Box>
                                </Stack>
                                <Stack spacing={1} direction="row" alignItems="center">
                                    <Tooltip title={enquiry.calculation.destination_country.name}>
                                        <Chip label={enquiry.calculation.destination_country.alpha2} variant="outlined" sx={{cursor:'pointer'}} />
                                    </Tooltip>
                                    <Box>{enquiry.calculation.destination_postal_code} {enquiry.calculation.destination_city}</Box>
                                </Stack>
                            </Stack>
                          </Stack>
                    </Grid>
                    <Grid xs={3} item>
                        <Stack spacing={2}>
                            <Typography variant="h2">Menge</Typography>
                            <Box>
                                <Grid container>
                                    
                                    {enquiry.volume ? (
                                    <>
                                    <Grid item xs={6}>Volumen</Grid>
                                    <Grid item xs={6}>{enquiry.volume.toCommaString()} m³</Grid>
                                    </>
                                    ) : null}

                                    {enquiry.weight ? (
                                    <>
                                    <Grid item xs={6}>Gewicht</Grid>
                                    <Grid item xs={6}>{enquiry.weight.toCommaString()} kg</Grid>
                                    </>
                                    ) : null}

                                    {enquiry.living_area ? (
                                    <>
                                    <Grid item xs={6}>Wohnfläche</Grid>
                                    <Grid item xs={6}>{enquiry.living_area.toCommaString()} m²</Grid>
                                    </>
                                    ) : null}

                                    {enquiry.persons ? (
                                    <>
                                    <Grid item xs={6}>Personen</Grid>
                                    <Grid item xs={6}>{enquiry.persons}</Grid>
                                    </>
                                    ) : null}

                                    {enquiry.weight_acw ? (
                                    <>
                                    <Grid item xs={6}>ACW Gewicht</Grid>
                                    <Grid item xs={6}>{enquiry.weight_acw.toCommaString()} kg</Grid>
                                    </>
                                    ) : null}

                                    {enquiry.weight_netto ? (
                                    <>
                                    <Grid item xs={6}>Nettogewicht</Grid>
                                    <Grid item xs={6}>{enquiry.weight_netto.toCommaString()} kg</Grid>
                                    </>
                                    ) : null}

                                </Grid>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid xs={3} item>
                        <Stack spacing={2}>
                            <Typography variant="h2">Wunschtermin</Typography>
                            <Box>{dayjs(enquiry.calculation.date).format('DD.MM.YYYY')}</Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>

            <Divider />
        
            <Box>
                <Grid container spacing={2}>
                    <Grid xs={3} item>
                        <Stack spacing={2}>
                            <Typography variant="h2">Name</Typography>
                            <Box>{enquiry.first_name} {enquiry.last_name}</Box>
                        </Stack>
                    </Grid>
                    <Grid xs={3} item>
                        <Stack spacing={2}>
                            <Typography variant="h2">Firma</Typography>
                            <Box>{enquiry.company_name ? enquiry.company_name : '-'}</Box>
                        </Stack>
                    </Grid>
                    <Grid xs={3} item>
                        <Stack spacing={2}>
                            <Typography variant="h2">Telefon</Typography>
                            {enquiry.phone &&
                            <Box><a href={'tel:'+enquiry.phone.toPhoneLink()}>{enquiry.phone}</a></Box>}
                        </Stack>
                    </Grid>
                    <Grid xs={3} item>
                        <Stack spacing={2}>
                            <Typography variant="h2">E-Mail</Typography>
                            <Box><a href={'mailto:'+enquiry.email}>{enquiry.email}</a></Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>

        </Stack>

    </Paper>
  )
}

export default EnquiryParameters
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material'
import React from 'react'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

const Search = ({search, setSearch}) => {
  
    const clear = () => {
        setSearch('');
    }

    const onKeyUp = (e) => {
        if(e.key === 'Escape') {
            clear();
          }
    }
  
    return (
    <Stack direction="row" spacing={2} alignItems="center" sx={{width:'100%'}}>
        <TextField fullWidth size="small" 
            label="Suche" 
            value={search} 
            onChange={(e) => setSearch(e.target.value)}
            onKeyUp={onKeyUp}
            InputProps={{
              endAdornment: (
                  <InputAdornment position="end" onClick={clear} sx={{cursor:'pointer'}}>
                    <ClearOutlinedIcon />
                  </InputAdornment>
                ),
              }}
        />
  </Stack>
  )
}

export default Search
import { Box, Button, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import useApi from '../hooks/useApi';
import dayjs from 'dayjs';
import StatusSelect from './StatusSelect';
import nl2br from 'react-nl2br';

const EnquiryStatuses = ({enquiry, setEnquiry, getEnquiries}) => {

    const api = useApi();
    const [enquiryStatus, setEnquiryStatus] = useState({
        enquiry_id: enquiry.id,
        status_id: enquiry.enquiry_statuses.length ? enquiry.enquiry_statuses[enquiry.enquiry_statuses.length-1].status_id : null,
        comment: null
    });
    const [isProcessing, setIsProcessing] = useState(false);
    
    const add = async() => {
        setIsProcessing(true);
        const data = await api.addEnquiryStatus(enquiryStatus);
        if(data) {
            setEnquiry({...enquiry, enquiry_statuses:data.enquiry_statuses});
            setEnquiryStatus({...enquiryStatus, comment: null})
            getEnquiries();

        }
        setIsProcessing(false);
    }

    const setStatusId = (statusId) => {
        setEnquiryStatus({...enquiryStatus, status_id: statusId})
    }

    return (
        
        <Paper className="spacing-2" sx={{height:'100%'}}>
            <Stack spacing={2}>
                
                <Typography variant="h2">Status</Typography>
        
                <Stack spacing={2}>
                    
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow></TableRow>
                            </TableHead>
                            <TableBody>
                                {enquiry.enquiry_statuses.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{dayjs(item.created_at).format('DD.MM.YYYY')} {dayjs(item.created_at).format('HH:mm')}</TableCell>
                                    <TableCell>{item.user && item.user.first_name ? item.user.first_name : ''} {item.user && item.user.last_name ? item.user.last_name : ''}</TableCell>
                                    <TableCell>{item.status.name}</TableCell>
                                    <TableCell>{nl2br(item.comment)}</TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <StatusSelect statusId={enquiryStatus.status_id} setStatusId={setStatusId} />

                    <TextField multiline size="small"
                        value={enquiryStatus.comment ? enquiryStatus.comment : ''}
                        onChange={(e) => setEnquiryStatus({...enquiryStatus, comment: e.target.value})}
                    />

                    <Box>
                        <Button variant="contained" disabled={isProcessing} onClick={add}>Speichern</Button>
                    </Box>

                </Stack>
            </Stack>
        </Paper>
    )
}

export default EnquiryStatuses
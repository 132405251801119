import { Box } from '@mui/material'
import React from 'react'

const PageBody = ({children}) => {
  return (
    <Box sx={{flexGrow:1, overflowY:'auto'}}>
        <Box sx={{padding:'15px'}}>
            {children}
        </Box>
    </Box>
  )
}

export default PageBody
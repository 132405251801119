import { Autocomplete, Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useContext, useEffect, useState } from 'react'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import useApi from '../hooks/useApi';
import MainContext from '../contexts/MainContext';
import Search from './Search';

const AirCountryParameter = ({airCountryParameters, getAirCountryParameters, insuranceClasses, customsClasses}) => {
  
  const {countries} = useContext(MainContext);
  const api = useApi();
  const [airCountryParameter, setAirCountryParameter] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isProcessing, setIsProcessing] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    setFilteredItems(airCountryParameters.filter(parameter => (search === '' || parameter.country.name.toLowerCase().includes(search.toLowerCase()))));
    setPage(0);
  }, [airCountryParameters, search])

  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const getCountryById = (id) => {
    const items = countries.filter((item) => item.id === id);
    return items.length ? items[0] : null; 
  }

  const isFormDisabled = (parameter) => {
    if(!parameter.id && !parameter.country_id) return true;
    if(
      !parameter.loading_rate || 
      !parameter.unloading_rate || 
      !parameter.transport_rate ||
      !parameter.insurance_class_id ||
      !parameter.customs_class_id ||
      !parameter.thc_rate
    ) return true;
  }

  const add = () => {
    setAirCountryParameter({
      country_id: '',
      insurance_class_id : '',
      customs_class_id : '',
      loading_rate: '',
      unloading_rate: '',
      transport_rate: '',
      thc_rate: ''
    });
  }

  const edit = (parameter) => {
    setAirCountryParameter({
      id: parameter.id,
      _country_id : parameter.country_id,
      insurance_class_id : parameter.insurance_class_id,
      customs_class_id : parameter.customs_class_id,
      loading_rate: parameter.loading_rate,
      unloading_rate: parameter.unloading_rate,
      transport_rate: parameter.transport_rate,
      thc_rate : parameter.thc_rate
    }); 
  }

  const save = async() => {
    setIsProcessing(true);
    const data = airCountryParameter.id ? await api.updateAirCountryParameter(airCountryParameter) : await api.addAirCountryParameter(airCountryParameter);
    if(data) {
      setAirCountryParameter(null);
      getAirCountryParameters();
    }
    setIsProcessing(false);
  }

  const remove = async() => {
    setIsProcessing(true);
    const data = await api.removeAirCountryParameter(airCountryParameter.id);
    if(data) {
      setAirCountryParameter(null);
      getAirCountryParameters();
    }
    setIsProcessing(false);
  }

  const abort = () => {
    setAirCountryParameter(null);
  }
  
  return (
    <>
      <Paper className="spacing-2" sx={{height:'100%'}}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
            <Typography variant="h2">Kosten je Land</Typography>
            <Button variant="outlined" onClick={add}>Hinzufügen</Button>
          </Stack>
          <Search search={search} setSearch={setSearch} />
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow sx={{verticalAlign:'top'}}>
                  <TableCell sx={{width:'200px'}}><Typography variant="caption">Land</Typography></TableCell>
                  <TableCell><Typography variant="caption">Beladen<br/>EUR / kg</Typography></TableCell>
                  <TableCell><Typography variant="caption">Entladen<br />EUR / kg</Typography></TableCell>
                  <TableCell><Typography variant="caption">Transport<br/>EUR / km</Typography></TableCell>
                  <TableCell><Typography variant="caption">Versicherung</Typography></TableCell>
                  <TableCell><Typography variant="caption">Zoll</Typography></TableCell>
                  <TableCell><Typography variant="caption">THC<br />EUR / kg</Typography></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((parameter) => {
                  return (
                    <TableRow key={parameter.id}>
                      <TableCell>{parameter.country.name}</TableCell>
                      <TableCell>{parameter.loading_rate.toCommaString()}</TableCell>
                      <TableCell>{parameter.unloading_rate.toCommaString()}</TableCell>
                      <TableCell>{parameter.transport_rate.toCommaString()}</TableCell>
                      <TableCell>{parameter.insurance_class.name}</TableCell>
                      <TableCell>{parameter.customs_class.name}</TableCell>
                      <TableCell>{parameter.thc_rate.toCommaString()}</TableCell>
                      <TableCell sx={{textAlign:'right'}}>
                        <IconButton onClick={() => edit(parameter)}>
                          <EditOutlinedIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
              })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination 
                    count={filteredItems.length} 
                    page={page} 
                    rowsPerPage={rowsPerPage} 
                    rowsPerPageOptions={[]}
                    onPageChange={onPageChange}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Stack>
      </Paper>
      <Modal open={airCountryParameter !== null} onClose={abort}>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{minHeight:'100%'}}>
        {airCountryParameter !== null ? (
        <Paper className="spacing-2">
          <Stack spacing={2}>
            <Typography variant="h2">Kosten</Typography>
            <Autocomplete
              size="small"
              options={countries}
              value={getCountryById(airCountryParameter.id ? airCountryParameter._country_id : airCountryParameter.country_id)}
              onChange={(e, value) => setAirCountryParameter({...airCountryParameter, country_id: value ? value.id : null})}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Land" />}
              disabled={airCountryParameter.id ? true : false}
            />
            <TextField
              size="small"
              label="Beladen"
              InputProps={{
                endAdornment: <InputAdornment position="start">EUR / kg</InputAdornment>,
              }}
              value={airCountryParameter.loading_rate.toCommaString()}
              onChange={(e) => setAirCountryParameter({...airCountryParameter, loading_rate:e.target.value.toFloatString()})}
            />
            <TextField
              size="small"
              label="Entladen"
              InputProps={{
                endAdornment: <InputAdornment position="start">EUR / kg</InputAdornment>,
              }}
              value={airCountryParameter.unloading_rate.toCommaString()}
              onChange={(e) => setAirCountryParameter({...airCountryParameter, unloading_rate:e.target.value.toFloatString()})}
            />
            <TextField
              size="small"
              label="Transport"
              InputProps={{
                endAdornment: <InputAdornment position="start">EUR / km</InputAdornment>,
              }}
              value={airCountryParameter.transport_rate.toCommaString()}
              onChange={(e) => setAirCountryParameter({...airCountryParameter, transport_rate:e.target.value.toFloatString()})}
            />
            <TextField
              size="small"
              label="THC"
              InputProps={{
                endAdornment: <InputAdornment position="start">EUR / kg</InputAdornment>,
              }}
              value={airCountryParameter.thc_rate.toCommaString()}
              onChange={(e) => setAirCountryParameter({...airCountryParameter, thc_rate:e.target.value.toFloatString()})}
            />
            <FormControl fullWidth>
              <InputLabel size="small">Versicherungsklasse</InputLabel>
              <Select size="small"
                  fullWidth
                  value={airCountryParameter.insurance_class_id}
                  onChange={(e) => setAirCountryParameter({...airCountryParameter, insurance_class_id: e.target.value})}
                  label="Versicherungsklasse"
                  >
                  {insuranceClasses.map((item) => (
                      <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel size="small">Zollklasse</InputLabel>
              <Select size="small"
                  fullWidth
                  value={airCountryParameter.customs_class_id}
                  onChange={(e) => setAirCountryParameter({...airCountryParameter, customs_class_id: e.target.value})}
                  label="Versicherungsklasse"
                  >
                  {customsClasses.map((item) => (
                      <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Stack direction="row" spacing={2}>
              {airCountryParameter.id ? (
              <Button variant="outlined" color="error" onClick={remove} disabled={isProcessing}>Löschen</Button>
              ) : null}
              <Button variant="outlined" onClick={abort}>Abbrechen</Button>
              <Button variant="contained" onClick={save} disabled={isProcessing || isFormDisabled(airCountryParameter)}>Speichern</Button>
            </Stack>
          </Stack>
        </Paper>
        ): null}
        </Stack>
      </Modal>
    </>
  )
}

export default AirCountryParameter
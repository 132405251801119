import { Box, Button, Chip, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs';
import MainContext from '../contexts/MainContext';
import SetCurrency from './SetCurrency';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import Search from './Search';
import StatusSelect from './StatusSelect';
import StatusIcon from './StatusIcon';
import FreightTypeIcon from './FreightTypeIcon';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';

const Enquiries = ({enquiries, getEnquiries, filter, setFilter, resetFilter, getEnquiry, show, isProcessing}) => {
  
    const {currency, freightTypes, countries} = useContext(MainContext);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const tableRef = useRef();
    
    const setSearch = (value) => {
        setFilter({...filter, search: value})
    }

    const setStatusId = (statusId) => {        
        setFilter({...filter, status_id:statusId})
    }

    const onPageChange = (event, newPage) => {
        setPage(newPage);
        tableRef.current.scrollTop = 0;
    };

    useEffect(() => {
        setPage(0);
    }, [enquiries]);
    
    return (
    
        <Stack sx={{height:'100%', display:show ? 'flex' : 'none'}}>

            <Box className="spacing-2" sx={{borderRadius:0, backgroundColor:'#303030'}}>
                <Stack spacing={2}>
                    
                    <Search search={filter.search} setSearch={setSearch}></Search>
                    
                    <Stack direction="row" spacing={2}>

                        <FormControl fullWidth>
                            <InputLabel size="small">Zeitraum</InputLabel>
                            <Select size="small"
                                value={filter.period}
                                onChange={(e) => setFilter({...filter, period: e.target.value})}
                                label="Zeitraum"
                                >
                                <MenuItem key={1} value={7}>7 Tage</MenuItem>
                                <MenuItem key={2} value={30}>30 Tage</MenuItem>
                                <MenuItem key={3} value={60}>60 Tage</MenuItem>
                                <MenuItem key={4} value={90}>90 Tage</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel size="small">Frachttyp</InputLabel>
                            <Select size="small"
                                value={filter.freight_type_id}
                                onChange={(e) => setFilter({...filter, freight_type_id: e.target.value})}
                                label="Frachttyp"
                                >
                                <MenuItem key={0} value="">&nbsp;</MenuItem>
                                {freightTypes.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                ))}
                                <MenuItem key="null" value="null">Unbekannt</MenuItem>
                            </Select>
                        </FormControl>

                        <StatusSelect statusId={filter.status_id} setStatusId={setStatusId} />

                        <FormControl fullWidth>
                            <InputLabel size="small" >Land</InputLabel>
                            <Select size="small"
                                value={filter.country_id}
                                onChange={(e) => setFilter({...filter, country_id: e.target.value})}
                                label="Land"
                                >
                                    <MenuItem key={0} value="">&nbsp;</MenuItem>
                                {countries.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField size="small" fullWidth
                            label="Postleitzahl"
                            value={filter.zipcode}
                            onChange={(e) => setFilter({...filter, zipcode:e.target.value})}
                        />

                        <FormControl fullWidth>
                            <InputLabel size="small" >Firma</InputLabel>
                            <Select size="small"
                                value={filter.has_company}
                                onChange={(e) => setFilter({...filter, has_company: e.target.value})}
                                label="Land"
                                >
                                <MenuItem key={0} value="">&nbsp;</MenuItem>
                                <MenuItem key={1} value={1}>ja</MenuItem>
                                <MenuItem key={2} value={0}>nein</MenuItem>
                            </Select>
                        </FormControl>

                    </Stack>

                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Button variant="outlined" onClick={resetFilter}>Reset</Button>
                        <IconButton sx={{opacity: isProcessing ? 0.5 : 1, transition: 'all 0.25s'}} onClick={getEnquiries} disabled={isProcessing}>
                                <CachedOutlinedIcon color="primary" fontSize="large" />
                        </IconButton>
                        <SetCurrency />
                    </Stack>
                </Stack>
            </Box>
            
            <Box sx={{flexGrow:1, position:'relative'}}>
                <Box sx={{position:'absolute', top:0, left:0, width:'100%', height:'100%'}}>
                    <TableContainer ref={tableRef} sx={{height:'100%'}}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>Referenz</TableCell>
                                    <TableCell>Zeit</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Firma</TableCell>
                                    <TableCell>Von</TableCell>
                                    <TableCell>Nach</TableCell>
                                    <TableCell>Vol.</TableCell>
                                    <TableCell>Gewicht</TableCell>
                                    <TableCell>Datum</TableCell>
                                    <TableCell sx={{textAlign:'right'}}>Kosten</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {enquiries.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                                <TableRow key={item.id} onClick={() => getEnquiry(item.id)} sx={{cursor:'pointer'}}>
                                    <TableCell>
                                        <StatusIcon status_name={item.status_name} status_color={item.status_color} parent_status_name={item.parent_status_name} parent_status_color={item.parent_status_color} />
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title={item.freight_type_name}>
                                            <Box><FreightTypeIcon id={item.freight_type_id} /></Box>
                                        </Tooltip> 
                                    </TableCell>
                                    <TableCell>{'#' + item.reference_no}</TableCell>
                                    <TableCell>
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <CalendarMonthOutlinedIcon fontSize="small" />
                                            <Box>{dayjs(item.created_at).format('DD.MM.YYYY')}</Box>
                                            <ScheduleOutlinedIcon fontSize="small" />
                                            <Box>{dayjs(item.created_at).format('HH:mm')}</Box>
                                        </Stack>
                                    </TableCell>
                                    <TableCell>{item.last_name}, {item.first_name}</TableCell>
                                    <TableCell>{item.company_name}</TableCell>
                                    <TableCell>
                                        <Stack spacing={1} direction="row" alignItems="center">
                                            <Tooltip title={`${item.origin_country_name}${item.origin_state ? ' | ' + item.origin_state : ''}`}>
                                                <Chip label={item.origin_country_alpha2} variant="outlined" sx={{cursor:'inherit'}} />
                                            </Tooltip>
                                            <Box>{item.origin_postal_code} {item.origin_city}</Box>
                                        </Stack>
                                    </TableCell>
                                    <TableCell>
                                        <Stack spacing={1} direction="row" alignItems="center">
                                        <Tooltip title={`${item.destination_country_name}${item.destination_state ? ' | ' + item.destination_state : ''}`}>
                                                <Chip label={item.destination_country_alpha2} variant="outlined" sx={{cursor:'inherit'}} />
                                            </Tooltip>
                                            <Box>{item.destination_postal_code} {item.destination_city}</Box>
                                        </Stack>
                                    </TableCell>
                                    <TableCell sx={{whiteSpace:'nowrap'}}>{item.volume ? item.volume.toCommaString() + ' m³' : ''}</TableCell>
                                    <TableCell sx={{whiteSpace:'nowrap'}}>{item.weight ? item.weight.toCommaString() + ' kg' : ''}</TableCell>
                                    <TableCell>{dayjs(item.date).format('DD.MM.YYYY')}</TableCell>
                                    <TableCell sx={{whiteSpace:'nowrap', textAlign:'right'}}>{item.total_price && currency ? item.total_price.toCurrency(currency) : ''}</TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Box>

            </Box>
            {enquiries.length > 0 ? (
            <TablePagination 
                component="div"
                count={enquiries.length} 
                page={page} 
                rowsPerPage={rowsPerPage} 
                rowsPerPageOptions={[]}
                onPageChange={onPageChange}
            />
            ) : null}
        </Stack>

        
  )
}

export default Enquiries
import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import MainContext from '../contexts/MainContext';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import SetCurrency from './SetCurrency';

const CalculationArticles = ({calculation, startExpanded}) => {

    const {currency} = useContext(MainContext);
    const [expanded, setExpanded] = useState(startExpanded === true ? startExpanded : false)

    return (
    <TableContainer>
        <Table size="small">
            <TableBody>
                
                <TableRow>
                    <TableCell sx={{fontSize:'1.5em'}}>Ergebnis</TableCell>
                    <TableCell sx={{textAlign:'right'}}>
                        <SetCurrency />
                    </TableCell>
                </TableRow>

                {expanded ? (
                <>
                {calculation.articles.map((item, index) => (
                <TableRow key={index}>
                    <TableCell sx={{fontSize:'inherit'}}>{item.name}</TableCell>
                    <TableCell sx={{fontSize:'inherit', textAlign:'right'}}>{item.price.toCurrency(currency)}</TableCell>
                </TableRow>
                ))}
                </>
                ) : null}

                {calculation.vat > 0 ? (
                <TableRow>
                    <TableCell sx={{fontSize:'1.5em'}}>Summe</TableCell>
                    <TableCell sx={{fontSize:'1.5em', textAlign:'right', fontSize:'20px'}}>{calculation.total_price.toCurrency(currency)}</TableCell>
                </TableRow>
                ) : null}

                {calculation.vat > 0 ? (
                <TableRow>
                    <TableCell sx={{fontSize:'inherit'}}>Mehrwertsteuer ({calculation.vat}%)</TableCell>
                    <TableCell sx={{fontSize:'inherit', textAlign:'right'}}>{(calculation.total_price * calculation.vat / 100).toCurrency(currency)}</TableCell>
                </TableRow>
                ) : null}

                <TableRow>
                    <TableCell sx={{fontSize:'1.5em'}}>Gesamtbetrag</TableCell>
                    <TableCell sx={{textAlign:'right', fontSize:'20px'}}>{(calculation.total_price + calculation.total_price * calculation.vat / 100).toCurrency(currency)}</TableCell>
                </TableRow>

                {!startExpanded ? (
                <TableRow>
                    <TableCell colSpan={2}>
                        <Stack direction="row" justifyContent="center">
                            <IconButton onClick={() => setExpanded(!expanded)}>
                            {expanded ? <ExpandLessOutlinedIcon fontSize="large" /> : <ExpandMoreOutlinedIcon fontSize="large" />}
                            </IconButton>
                        </Stack>
                    </TableCell>
                </TableRow>
                ) : null}

            </TableBody>
        </Table>
    </TableContainer>
  )
}

export default CalculationArticles
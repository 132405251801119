import { Box, Stack, ThemeProvider, Typography } from '@mui/material'
import React from 'react'
import { darkTheme2 } from '../Themes'
import Logo from './Logo'

const TopBar = ({label, children}) => {
  return (
    <ThemeProvider theme={darkTheme2}>    
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} sx={{height:'60px', flexShrink:0, backgroundColor:'#0B3D91', padding:'0 15px'}}>
          <Stack direction="row" spacing={2}>
            <Typography variant="h1">{label}</Typography>
            <Box>{children}</Box>
          </Stack>
          <Logo />
      </Stack>
    </ThemeProvider>
  )
}

export default TopBar
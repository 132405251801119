import { Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import '../Prototypes';
import useApi from '../hooks/useApi';

const SeaProfits = ({containerTypes}) => {
  
  const api = useApi();
  const [seaProfits, setSeaProfits] = useState([]);
  const [seaProfit, setSeaProfit] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(()=> {
    getSeaProfits();
  }, []);

  const getSeaProfits = async() => {
    const data = await api.getSeaProfits();
    if(data) {
      setSeaProfits(data.results);
    }
  };

  const add = () => {
    setSeaProfit({
      container_type_id: '',
      import_rate: '',
      export_rate: '',
      cross_rate: ''
    })
  }

  const edit = (item) => {
    setSeaProfit({
      id: item.id,
      container_type_id: item.container_type_id,
      import_rate: item.import_rate,
      export_rate: item.export_rate,
      cross_rate: item.cross_rate
    })
  }

  const save = async() => {
    setIsProcessing(true);
    const data = seaProfit.id ? await api.updateSeaProfit(seaProfit) : await api.addSeaProfit(seaProfit);
    if(data) {
      setSeaProfit(null);
      getSeaProfits();
    }
    setIsProcessing(false);
  }

  const remove = async() => {
    setIsProcessing(true);
    const data = await api.removeSeaProfit(seaProfit.id);
    if(data) {
      setSeaProfit(null);
      getSeaProfits();
    }
    setIsProcessing(false);
  }

  const abort = () => {
    setSeaProfit(null);
  }

  return (
    <>
      <Paper className="spacing-2" sx={{height:'100%'}}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
            <Typography variant="h2">Profits</Typography>
            <Button variant="outlined" onClick={add}>Hinzufügen</Button>
          </Stack>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow sx={{verticalAlign:'top'}}>
                  <TableCell><Typography variant="caption">Container Typ</Typography></TableCell>
                  <TableCell><Typography variant="caption">Import<br/>EUR</Typography></TableCell>
                  <TableCell><Typography variant="caption">Export<br/>EUR</Typography></TableCell>
                  <TableCell><Typography variant="caption">Quer<br/>EUR</Typography></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {seaProfits.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.container_type.name}</TableCell>
                  <TableCell>{item.import_rate}</TableCell>
                  <TableCell>{item.export_rate}</TableCell>
                  <TableCell>{item.cross_rate}</TableCell>
                  <TableCell sx={{textAlign:'right'}}>
                    <IconButton onClick={() => edit(item)}>
                      <EditOutlinedIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Paper>
      <Modal open={seaProfit !== null} onClose={abort}>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{minHeight:'100%'}}>
        {seaProfit !== null ? (
        <Paper className="spacing-2">
          <Stack spacing={2}>
            <Typography variant="h2">Profit</Typography>
            <FormControl fullWidth>
              <InputLabel size="small" id="select-label">Container Typ</InputLabel>
              <Select size="small"
                  fullWidth
                  labelId="select-label"
                  value={seaProfit.container_type_id}
                  onChange={(e) => setSeaProfit({...seaProfit, container_type_id: e.target.value})}
                  label="Container Typ"
                  >
                  {containerTypes.map((item) => (
                      <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextField
                size="small"
                label="Import"
                InputProps={{
                  endAdornment: <InputAdornment position="start">EUR</InputAdornment>,
                }}
                value={seaProfit.import_rate.toCommaString()}
                onChange={(e) => setSeaProfit({...seaProfit, import_rate:e.target.value.toFloatString()})}
            />
            <TextField
                size="small"
                label="Export"
                InputProps={{
                  endAdornment: <InputAdornment position="start">EUR</InputAdornment>,
                }}
                value={seaProfit.export_rate.toCommaString()}
                onChange={(e) => setSeaProfit({...seaProfit, export_rate:e.target.value.toFloatString()})}
            />
            <TextField
                size="small"
                label="Export"
                InputProps={{
                  endAdornment: <InputAdornment position="start">EUR</InputAdornment>,
                }}
                value={seaProfit.cross_rate.toCommaString()}
                onChange={(e) => setSeaProfit({...seaProfit, cross_rate:e.target.value.toFloatString()})}
            />
            <Stack direction="row" spacing={2}>
                {seaProfit.id ? (
                <Button variant="outlined" color="error" onClick={remove} disabled={isProcessing}>Löschen</Button>
                ) : null}
                <Button variant="outlined" onClick={abort}>Abbrechen</Button>
                <Button variant="contained" onClick={save} disabled={isProcessing || !seaProfit.container_type_id || !seaProfit.import_rate || !seaProfit.export_rate || !seaProfit.cross_rate}>Speichern</Button>
              </Stack>
          </Stack>
        </Paper>
        ): null}
        </Stack>
      </Modal>
    </>
  )
}

export default SeaProfits
import { Autocomplete, Button, Checkbox, FormControlLabel, FormGroup, IconButton, Modal, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import '../Prototypes';
import useApi from '../hooks/useApi';
import MainContext from '../contexts/MainContext'
import Search from './Search';

const Seaports = ({containers}) => {
  
  const {countries} = useContext(MainContext);
  const api = useApi();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [seaports, setSeaports] = useState([]);
  const [seaport, setSeaport] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    setFilteredItems(seaports.filter(item => (
        search === '' ||
        item.country.name.toLowerCase().includes(search.toLowerCase()) ||
        item.name.toLowerCase().includes(search.toLowerCase()) ||
        item.unlocode.toLowerCase().includes(search.toLowerCase()) 
      )));
      setPage(0);
  }, [seaports, search])

  useEffect(() => {
    getSeaports();
  }, []);

  const getSeaports = async() => {
    const data = await api.getSeaports();
    if(data) {
      setSeaports(data.results);
    }
  }

  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const getCountryById = (id) => {
    const items = countries.filter((item) => item.id === id);
    return items.length ? items[0] : null; 
  }

  const setContainer = (containerId, checked) => {
    var items = seaport.containers.filter(item => item.container_id !== containerId);
    if(checked) {
      items.push({container_id:containerId});
    }
    setSeaport({...seaport, containers:items})
  }

  const isContainer = (containerId) => {
    return seaport.containers.find(item => item.container_id === containerId) !== undefined;
  }

  const add = () => {
    setSeaport({
      country_id: '',
      name: '',
      unlocode: '',
      lat: '',
      lng: '',
      containers: []
    })
  }

  const edit = (item) => {
    setSeaport(item);
  }

  const save = async() => {
    setIsProcessing(true);
    const data = seaport.id ? await api.updateSeaport(seaport) : await api.addSeaport(seaport);
    if(data) {
      setSeaport(null);
      getSeaports();
    }
    setIsProcessing(false);
  }

  const remove = async() => {
    setIsProcessing(true);
    const data = await api.removeSeaport(seaport.id);
    if(data.success) {
      setSeaport(null);
      getSeaports();
    }
    setIsProcessing(false);
  }

  const abort = () => {
    setSeaport(null);
  }


  return (
    <>
      <Paper className="spacing-2" sx={{height:'100%'}}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Typography variant="h2">Seehäfen</Typography>
              <Button variant="outlined" onClick={add}>Hinzufügen</Button>
          </Stack>
          <Search search={search} setSearch={setSearch}></Search>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{width:'200px'}}><Typography variant="caption">Land</Typography></TableCell>
                  <TableCell sx={{width:'300px'}}><Typography variant="caption">Name</Typography></TableCell>
                  <TableCell><Typography variant="caption">UN/LOCODE</Typography></TableCell>
                  <TableCell><Typography variant="caption">Lat</Typography></TableCell>
                  <TableCell><Typography variant="caption">Lng</Typography></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.country.name}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.unlocode}</TableCell>
                  <TableCell>{item.lat}</TableCell>
                  <TableCell>{item.lng}</TableCell>
                  <TableCell sx={{textAlign:'right'}}>
                    <IconButton onClick={() => edit(item)}>
                      <EditOutlinedIcon fontSize="small" />
                    </IconButton>
                </TableCell>
                </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination 
                    count={filteredItems.length} 
                    page={page} 
                    rowsPerPage={rowsPerPage} 
                    rowsPerPageOptions={[]}
                    onPageChange={onPageChange}
                  />
                </TableRow>
            </TableFooter>
            </Table>
          </TableContainer>
        </Stack>
      </Paper>
      <Modal open={seaport !== null} onClose={abort}>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{minHeight:'100%'}}>
        {seaport !== null ? (
        <Paper className="spacing-2">
          <Stack spacing={2}>
            <Typography variant="h2">Seehafen</Typography>
            <Autocomplete size="small"
              options={countries}
              value={getCountryById(seaport.country_id)}
              onChange={(e, value) => setSeaport({...seaport, country_id: value ? value.id : null})}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Land" />}
            /> 
            <TextField
              size="small"
              label="Name"
              value={seaport.name}
              onChange={(e) => setSeaport({...seaport, name:e.target.value})}
            />
            <TextField
              size="small"
              label="UN/LOCODE"
              value={seaport.unlocode}
              onChange={(e) => setSeaport({...seaport, unlocode:e.target.value})}
            />
            <Stack direction="row" spacing={2}>
              <TextField
                size="small"
                label="Lat"
                value={seaport.lat}
                onChange={(e) => setSeaport({...seaport, lat:e.target.value.toLatLong()})}
              />
              <TextField
                size="small"
                label="Lng"
                value={seaport.lng}
                onChange={(e) => setSeaport({...seaport, lng:e.target.value.toLatLong()})}
              />
            </Stack>
            <Typography variant="button">Container</Typography>
            <FormGroup>
              {containers.map(container => (
                <FormControlLabel key={container.id} control={<Checkbox checked={isContainer(container.id)} onChange={(e) => setContainer(container.id, e.target.checked)} size="small" />} label={container.name} />
              ))}
            </FormGroup>
            <Stack direction="row" spacing={2}>
              {seaport.id ? (
              <Button variant="outlined" color="error" onClick={remove} disabled={isProcessing}>Löschen</Button>
              ) : null}
              <Button variant="outlined" onClick={abort}>Abbrechen</Button>
              <Button variant="contained" onClick={save} disabled={isProcessing}>Speichern</Button>
            </Stack>
          </Stack>
        </Paper>
        ): null}
        </Stack>
      </Modal>
    </>

  )
}

export default Seaports
import { Autocomplete, Box, Button, Grid, InputAdornment, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import useApi from '../hooks/useApi';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import MainContext from '../contexts/MainContext';

const AirFreightRates = () => {
  
    const {countries} = useContext(MainContext);
    const api = useApi();
    const [originCountryId, setOriginCountryId] = useState('');
    const [destinationCountryId, setDestinationCountryId] = useState('');
    const [originAirports, setOriginAirports] = useState([]);
    const [destinationAirports, setDestinationAirports] = useState([]);
    const [rates, setRates] = useState([]);
    const [changedRates, setChangedRates] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);

    const getCountryById = (id) => {
        const items = countries.filter((item) => item.id === id);
        return items.length ? items[0] : null; 
    }

    const getOriginAirports = async(countryId) => {
        setOriginCountryId(countryId);
        if(countryId) {
            const data = await api.getAirportsByCountryId(countryId);
            if(data) {
                setOriginAirports(data.results);
            }
        }
        else {
            setOriginAirports([]);
        }
    }

    const getDestinationAirports = async(countryId) => {
        setDestinationCountryId(countryId);
        if(countryId) {
            const data = await api.getAirportsByCountryId(countryId);
            if(data) {
                setDestinationAirports(data.results);
            }
        }
        else {
            setDestinationAirports([]);
        }
    }

    useEffect(() => {
        if(originCountryId && destinationCountryId) {
            getAirFreightRates(originCountryId, destinationCountryId);
        }
        else {
            setRates([]);
        }
        setChangedRates([]);
    }, [originCountryId, destinationCountryId]);

    const getAirFreightRates = async(originCountryId, destinationCountryId) => {
        const data = await api.getAirFreightRates(originCountryId, destinationCountryId);
        if(data) {
            setRates(data.results);
        }
    }

    const getRate = (iataOrigin, iataDestination) => {
        var items = changedRates.filter((item) => item.iata_origin === iataOrigin && item.iata_destination === iataDestination);
        if(items.length) return items[0].rate;
        var items = rates.filter((item) => item.iata_origin === iataOrigin && item.iata_destination === iataDestination);
        if(items.length) return items[0].rate ? items[0].rate : '';
        return '';
    }

    const setChangedRate = (iataOrigin, iataDestination, changedRate) => {
        var updated = false;
        var rates = changedRates.map(rate => {
          if (rate.iata_origin === iataOrigin && rate.iata_destination === iataDestination) {
            updated = true;
            return {...rate, iata_origin:iataOrigin, iata_destination:iataDestination, rate:changedRate};
          }
          else {
            return rate;
          }
        });
        if(!updated) {
          rates.push({iata_origin:iataOrigin, iata_destination:iataDestination, rate:changedRate})
        }
        setChangedRates(rates);
      }


    const saveChangedRates = async() => {
        setIsProcessing(true);
        await api.addOrUpdateAirFreightRates({'changed_rates':changedRates});
        setIsProcessing(false);
    }

    return (
    <Paper className="spacing-2" sx={{width:'800px', height:'100%'}}>
        <Stack spacing={2} sx={{height:'100%'}}>
            <Typography variant="h2">Luftfracht Raten</Typography>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Autocomplete size="small" fullWidth
                            options={countries}
                            value={getCountryById(originCountryId)}
                            onChange={(e, value) => getOriginAirports(value ? value.id : '')}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label="Land" />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete size="small" fullWidth
                            options={countries}
                            value={getCountryById(destinationCountryId)}
                            onChange={(e, value) => getDestinationAirports(value ? value.id : '')}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label="Land" />}
                        /> 
                    </Grid>
                </Grid>
            </Box>
            <TableContainer sx={{minHeight:'1px', flexGrow:1}}>
                <Table stickyHeader>
                    <TableBody>
                        {originCountryId != destinationCountryId ? originAirports.map((originAirport) => {
                            return destinationAirports.map((destinationAirport) => {
                                return (
                                    <TableRow key={originAirport.id + '_' + destinationAirport.id}>
                                        <TableCell>
                                            {originAirport.iata}
                                        </TableCell>
                                        <TableCell>
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                <ArrowForwardIosOutlinedIcon />
                                                <TextField size="small" fullWidth
                                                    value={getRate(originAirport.iata, destinationAirport.iata).toCommaString()}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">EUR / kg</InputAdornment>
                                                    }}
                                                    onChange={(e) => setChangedRate(originAirport.iata, destinationAirport.iata, e.target.value.toFloatString())}
                                                />
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            {destinationAirport.iata}
                                        </TableCell>
                                        <TableCell sx={{textAlign:'right'}}>
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                <ArrowBackIosNewOutlinedIcon />
                                                <TextField size="small" fullWidth
                                                    value={getRate(destinationAirport.iata, originAirport.iata).toCommaString()}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">EUR / kg</InputAdornment>
                                                    }}
                                                    onChange={(e) => setChangedRate(destinationAirport.iata, originAirport.iata, e.target.value.toFloatString())}
                                                />
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }) : null}
                    </TableBody>
                </Table>
            </TableContainer>
            <Stack direction="row" justifyContent="flex-end">
                <Button variant="contained" onClick={saveChangedRates} disabled={isProcessing || !changedRates.length}>Speichern</Button>
            </Stack>
        </Stack>
    </Paper>
  )
}

export default AirFreightRates
import { createTheme } from "@mui/material";

const typography = {

  fontSize: 14,
  h1: {
    fontSize: '2.5rem',
    fontWeight: 400,
  },
  h2: {
    fontSize: '1.5rem',
    fontWeight: 300
  },
  h3: {
    fontSize: '1.25rem',
    fontWeight: 300
  }

}

export const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      background: {
        default: '#212121',
        paper: '#303030'
      },
      primary: {
        main: '#bdd4fa',
        light: '#dee9fc',
        dark: '#adc9f9'
      }

    },
    typography
});

export const darkTheme2 = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main:'#FFFFFF',
      light:'#FFFFFF'
    }
  },
  typography
});

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
  typography
});
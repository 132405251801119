import { Box, IconButton, Paper, Stack } from '@mui/material'
import React, { useContext } from 'react'
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import { Link, useNavigate } from 'react-router-dom';
import GlobalContext from '../contexts/GlobalContext';
import useApi from '../hooks/useApi';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

const Menu = () => {
  
    const navigate = useNavigate();
    const api = useApi();
    const { user, setUser, token, setToken } = useContext(GlobalContext);
    
    const logout = async() => {
        const data = await api.logout();
        if(data) {
            navigate('/');
            setToken('');
            setUser(null);
        }
    }
  
    return (
    <Box sx={{width:'60px', height:'100%', padding:'60px 0 10px 0', flexShrink:0, backgroundColor:'#0B3D91'}}>
        <Stack spacing={1} alignItems="center" justifyContent="space-between" sx={{height:'100%'}}>
            <Stack spacing={1}>
                <Link to="/">
                    <IconButton>
                        <OfflineBoltOutlinedIcon fontSize="large" />
                    </IconButton>
                </Link>
                <Link to="/calculator">
                    <IconButton>
                        <CalculateOutlinedIcon fontSize="large" />
                    </IconButton>
                </Link>
                {[1,2].includes(user.user_role_id) ? (
                <Link to="/settings">
                    <IconButton>
                        <SettingsOutlinedIcon fontSize="large" />
                    </IconButton>
                </Link>
                ) : null}
            </Stack>
            <IconButton onClick={logout}>
                <LogoutOutlinedIcon fontSize="large" />
            </IconButton>
        </Stack>
    </Box>
  )
}

export default Menu
import { Box, Button, Paper, Stack, TextField, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import useApi from '../hooks/useApi'
import GlobalContext from '../contexts/GlobalContext';

const Login = () => {
  
  const api = useApi();
  const { user, setUser, token, setToken } = useContext(GlobalContext);
  const [login, setLogin] = useState({username: '', password:''});
  const [isProcessing, setIsProcessing] = useState(false);

  const doLogin = async() => {
    setIsProcessing(true);
    const data = await api.login(login);
    if(data) {
      setUser(data.user);
      setToken(data.token);
    }
    setIsProcessing(false);
  }

  const onKeyUp = (e) => {
    if(e.key === 'Enter' && login.username && login.password) {
      doLogin();
    }
  }
  
  return (
    <Stack sx={{width:'100%', height:'100%'}} alignItems="center" justifyContent="center">
      <Paper className="spacing-2">
        <Stack spacing={2}>
          <Typography variant="h2">Login</Typography>
          <TextField size="small" label="Benutzername"
            value={login.username}
            onChange={(e) => setLogin({...login, username:e.target.value})}
            onKeyUp={onKeyUp}
          />
          <TextField size="small" label="Passwort"
            type="password"
            autoComplete="current-password"
            value={login.password}
            onChange={(e) => setLogin({...login, password:e.target.value})}
            onKeyUp={onKeyUp}
          />
          <Button variant="contained" onClick={doLogin} disabled={isProcessing || !login.username || !login.password}>Login</Button>
        </Stack>
      </Paper>
    </Stack>
  )
}

export default Login
import React, { useEffect, useState } from 'react'
import AirCountryParameter from '../components/AirCountryParameter'
import AirParameter from '../components/AirParameter'
import InsuranceClasses from '../components/InsuranceClasses'
import CustomsClasses from '../components/CustomsClasses'
import useApi from '../hooks/useApi'
import { Grid } from '@mui/material'
import Airports from '../components/Airports'
import SeasonSurcharges from '../components/SeasonSurcharges'


const AirParametersPage = () => {
  const freightTypeId = 3;
  const api = useApi();
  const [insuranceClasses, setInsuranceClasses] = useState([]);
  const [customsClasses, setCustomsClasses] = useState([]);
  const [airCountryParameters, setAirCountryParameters] = useState([]);
  
  useEffect(() => {
    getInsuranceClasses();
    getCustomsClasses();
    getAirCountryParameters();
  }, []);

  const getInsuranceClasses = async() => {
    const data = await api.getInsuranceClassesByFreightTypeId(freightTypeId);
    if(data) {
      setInsuranceClasses(data.results);
    }
	}

  const getCustomsClasses = async() => {
    const data = await api.getCustomsClassesByFreightTypeId(freightTypeId);
    if(data) {
      setCustomsClasses(data.results);
    }
	}

  const getAirCountryParameters = async() => {
    const data = await api.getAirCountryParameters();
    if(data) {
      setAirCountryParameters(data.results);
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <AirCountryParameter airCountryParameters={airCountryParameters} getAirCountryParameters={getAirCountryParameters} insuranceClasses={insuranceClasses} customsClasses={customsClasses} />
      </Grid>
      <Grid item xs={6}>
        <Airports />
      </Grid>
      <Grid item xs={4}>
        <AirParameter />
      </Grid>
      <Grid item xs={4}>
        <InsuranceClasses freightTypeId={freightTypeId} insuranceClasses={insuranceClasses} getInsuranceClasses={getInsuranceClasses} getCountryParmeters={getAirCountryParameters} />
      </Grid>
      <Grid item xs={4}>
        <CustomsClasses freightTypeId={freightTypeId} customsClasses={customsClasses} getCustomsClasses={getCustomsClasses}  getCountryParmeters={getAirCountryParameters}  />
      </Grid>
      <Grid item xs={4}>
        <SeasonSurcharges freightTypeId={freightTypeId} />
      </Grid>
    </Grid>
  )
}

export default AirParametersPage
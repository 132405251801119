import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import React from 'react'
import AirFreightRates from '../components/AirFreightRates'

const AirFreightRatesPage = () => {
  
  return (
    <Box sx={{height:'100%'}}>
      <AirFreightRates />
    </Box>
  )
}

export default AirFreightRatesPage
import { Button, IconButton, Modal, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import useApi from '../hooks/useApi';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MainContext from '../contexts/MainContext'

const Currencies = () => {
  
  const {currencies, getCurrencies} = useContext(MainContext);
  const api = useApi();
  const [currency, setCurrency] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

	const add = () => {
    setCurrency({
      code: '',
      rate: '',
      sort: ''
    });
  }
	
  const edit = (item) => {
    setCurrency(item);
  }

  const save = async() => {
    setIsProcessing(true);
    const data = currency.id ? await api.updateCurrency(currency) : await api.addCurrency(currency);
    if(data) {
      setCurrency(null);
      getCurrencies();
    }
    setIsProcessing(false);
  }

  const remove = async() => {
    setIsProcessing(true);
    const data = await api.removeCurrency(currency.id);
    if(data) {
        setCurrency(null);
        getCurrencies();
    }
    setIsProcessing(false);
  }

  const abort = () => {
    setCurrency(null);
  }

	
	return (
    <>
			<Paper className="spacing-2" sx={{height:'100%'}}>
				<Stack spacing={2}>
						<Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Typography variant="h2">Währungen</Typography>
              <Button variant="outlined" onClick={add}>Hinzufügen</Button>
            </Stack>
				</Stack>
				<TableContainer>
					<Table size="small">
						<TableHead>
							<TableRow sx={{verticalAlign:'top'}}>
                <TableCell><Typography variant="caption">Code</Typography></TableCell>
								<TableCell><Typography variant="caption">Rate</Typography></TableCell>
                <TableCell><Typography variant="caption">Reihenfolge</Typography></TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{currencies.map((item) => (
							<TableRow key={item.id}>
                  <TableCell sx={{whiteSpace:'nowrap'}}>{item.code}</TableCell>
									<TableCell sx={{whiteSpace:'nowrap'}}>{item.rate.toCommaString()}</TableCell>
                  <TableCell sx={{whiteSpace:'nowrap'}}>{item.sort}</TableCell>
									<TableCell sx={{textAlign:'right'}}>
                      <IconButton onClick={() => edit(item)}>
                        <EditOutlinedIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
							</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>

			</Paper>
			<Modal open={currency !== null} onClose={abort}>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{minHeight:'100%'}}>
          {currency !== null ? (
          <Paper className="spacing-2">
						<Stack spacing={2}>
              <Typography variant="h2">Währung</Typography>
              <TextField
                size="small"
                label="Reihenfolge"
                value={currency.sort}
                onChange={(e) => setCurrency({...currency, sort:e.target.value})}
              />
              <TextField
                size="small"
                label="Code"
                value={currency.code}
                onChange={(e) => setCurrency({...currency, code:e.target.value})}
              />
							<TextField
                size="small"
                label="Rate"
                value={currency.rate.toCommaString()}
                onChange={(e) => setCurrency({...currency, rate:e.target.value.toFloatString()})}
              />
							<Stack direction="row" spacing={2}>
									{currency.id ? (
									<Button variant="outlined" color="error" onClick={remove} disabled={isProcessing}>Löschen</Button>
									) : null}
									<Button variant="outlined" onClick={abort}>Abbrechen</Button>
									<Button variant="contained" onClick={save} disabled={isProcessing || !currency.code || !currency.rate || !currency.sort}>Speichern</Button>
							</Stack>
						</Stack>
					</Paper>
          ): null}
        </Stack>
      </Modal>
    </>
  )
}

export default Currencies
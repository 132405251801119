import { Autocomplete, Box, Button, Checkbox, FormControl, Grid, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import useApi from '../hooks/useApi';
import MainContext from '../contexts/MainContext';
import Calculation from './Calculation';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const Calculator = () => {

  const minDate = dayjs();
  const { countries, freightTypes} = useContext(MainContext);
  const api = useApi();
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [freightTypeId, setFreightTypeId] = useState(1);
  const [volume, setVolume] = useState('');
  const [weight, setWeight] = useState('');
  const [calculation, setCalculation] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [date, setDate] = useState(dayjs(minDate).format('YYYY-MM-DD'));

  useEffect(() => {
    getServices();
  }, []);

  const getServices = async() => {
    const data = await api.getArticlesByArticleGroupId(2);
    if(data) {
      setServices(data.results);
    }
  }

  // origin
  const [originRequest, setOriginRequest] = useState({
      q:'',
      countryCode:''
  });
  const [originSuggests, setOriginSuggests] = useState([]);
  
  // destination
  const [destinationRequest, setDestinationRequest] =  useState({
      q:'',
      countryCode:''
  });
  const [destinationSuggests, setDestinationSuggests] = useState([]);

  const getCountryByCode = (countryCode) => {
      const items = countries.filter((item) => item.alpha3 === countryCode);
      return items.length ? items[0] : null; 
  }

  const handleOriginCountryChange = (e, value) => {
      setOriginRequest({...originRequest, countryCode: value ? value.alpha3 : '', q:''});
      setOriginSuggests([]);
      setOrigin(null);
  }

  const handleOriginChange = async(e, value) => {
      setOriginRequest({...originRequest, q:value ? value.title : ''});
      setOrigin(null);
      if(value !== null) {
          const data = await api.getLocation({id:value.id, lang:'de'})
          if(data) {
              setOrigin(data.result);
          }
      }
  }

  const handleDestinationCountryChange = (e, value) => {
      setDestinationRequest({...destinationRequest, countryCode: value ? value.alpha3 : '', q:''});
      setDestinationSuggests([]);
      setDestination(null);
  }

  const handleDestinationChange = async(e, value) => {
      setDestinationRequest({...destinationRequest, q:value ? value.title : ''});
      setDestination(null);
      if(value !== null) {
          const data = await api.getLocation({id:value.id, lang:'de'});
          if(data) {
              setDestination(data.result);
          }
      }
  }

  useEffect(() => {
      getOriginSuggests();
  }, [originRequest]);


  useEffect(() => {
      getDestinationSuggests();
  }, [destinationRequest]);

  const getOriginSuggests = async() => {
      if(originRequest.q && originRequest.countryCode) {
          const data = await api.getLocationsAutocomplete(originRequest);
          if(data.success) {
              setOriginSuggests(data.results);
          }
      }
  }

  const getDestinationSuggests = async() => {
      if(destinationRequest.q && destinationRequest.countryCode) {
          const data = await api.getLocationsAutocomplete(destinationRequest);
          if(data.success) {
              setDestinationSuggests(data.results);
          }
      }
  }

  useEffect(() => {
    setSelectedServices([]);
  }, [freightTypeId]);

  const calculate = async() => {
    
    setCalculation(null);
    setIsProcessing(true);

    let jsonBody = {
      origin:origin,
      destination:destination,
      freight_type_id:freightTypeId,
      services: selectedServices,
      date: date
    };

    if([1,2,4].includes(freightTypeId)) {
      jsonBody.volume = volume;
    }

    if([3].includes(freightTypeId)) {
      jsonBody.weight = weight;
    }

    const data = await api.addCalculation(jsonBody);
    if(data) {
      setCalculation(data.result);
    }
    setIsProcessing(false);

  }

  const isButtonDisabled = (origin, destination, volume, weight, date, isProcessing) => {
    if(isProcessing) return true;
    if(origin === null) return true;
    if(destination === null) return true;
    if(freightTypeId === 3 && !weight) return true;
    if(freightTypeId !== 3 && !volume) return true;
    if(date === null) return true;
    
  }

  useEffect(() => {
    setCalculation(null);
  }, [origin, destination, freightTypeId, volume, weight, services, date])


  return (
    
    <Stack sx={{height:'100%'}}>
      
      <Stack className="spacing-2" sx={{backgroundColor:'#303030'}} spacing={2}>
        
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Stack spacing={2}>
                <Typography variant="h2">Herkunft</Typography>
                <Autocomplete size="small" fullWidth
                    options={countries}
                    value={getCountryByCode(originRequest.countryCode)}
                    onChange={handleOriginCountryChange}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="Land" />}
                />
                <Autocomplete size="small" fullWidth
                    freeSolo={true}
                    filterOptions={(x) => x}
                    inputValue={originRequest.q}
                    options={originSuggests}
                    onChange={handleOriginChange}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => <TextField onChange={(e) => setOriginRequest({...originRequest, q:e.target.value})} {...params} label="Ort" />}
                    disabled={originRequest.countryCode === ''}
                />
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack spacing={2}>
                <Typography variant="h2">Ziel</Typography>
                <Autocomplete size="small" fullWidth
                    options={countries}
                    value={getCountryByCode(destinationRequest.countryCode)}
                    onChange={handleDestinationCountryChange}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="Land" />}
                />
                <Autocomplete size="small" fullWidth
                    freeSolo={true}
                    filterOptions={(x) => x}
                    inputValue={destinationRequest.q}
                    options={destinationSuggests}
                    onChange={handleDestinationChange}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => <TextField onChange={(e) => setDestinationRequest({...destinationRequest, q:e.target.value})} {...params} label="Ort" />}
                    disabled={destinationRequest.countryCode === ''}
                />
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack spacing={2}>
                <Typography variant="h2">Fracht / Menge</Typography>
                <FormControl fullWidth>
                  <InputLabel size="small">Frachttyp</InputLabel>
                  <Select size="small"
                      fullWidth
                      value={freightTypeId}
                      onChange={(e) => setFreightTypeId(parseInt(e.target.value))}
                      label="Frachttyp"
                      >
                      {freightTypes.map((item) => (
                          <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>

                {[1,2,4].includes(freightTypeId) ? (
                  <TextField size="small" fullWidth
                    label="Volumen"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m³</InputAdornment>,
                    }}
                    value={volume.toCommaString()}
                    onChange={(e) => setVolume(e.target.value.toFloatString())}
                  />
                  ) : null}
                  {[3].includes(freightTypeId) ? (
                  <TextField size="small" fullWidth
                    label="Gewicht"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                    }}
                    value={weight.toCommaString()}
                    onChange={(e) => setWeight(e.target.value.toFloatString())}
                  />
                  ) : null}

              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack spacing={2}>
                <Typography variant="h2">Services / Datum</Typography>
                <FormControl size="small">
                  <InputLabel>Services</InputLabel>
                  <Select
                    multiple
                    value={selectedServices}
                    onChange={(e) => setSelectedServices(e.target.value)}
                    input={<OutlinedInput label="Services" size="small" />}
                    renderValue={(selected) => selected.length + " ausgewählt"}
                    disabled={[1].includes(freightTypeId) ? false : true}
                  >
                    {services.map((service) => (
                      <MenuItem key={service.id} value={service}>
                        <Checkbox checked={selectedServices.find(selectedService => service.id === selectedService.id) ? true : false} />
                        <ListItemText primary={service.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                  <DatePicker 
                    format="DD.MM.YYYY" 
                    minDate={minDate} 
                    defaultValue={minDate} 
                    slotProps={{ textField: { size: 'small' } }}
                    onChange={(newValue) =>  setDate(dayjs(newValue).isValid() ? dayjs(newValue).format('YYYY-MM-DD') : null)}
                  ></DatePicker>
                </LocalizationProvider>
                
              </Stack>
            </Grid>
          </Grid>

        </Box>
        
        <Box>
          <Button variant="contained" disabled={isButtonDisabled(origin, destination, volume, weight, date, isProcessing)} onClick={calculate}>Kalkulieren</Button>
        </Box>
       
      </Stack>
      

      <Box sx={{flexGrow: 1, position:'relative'}}>

        <Box sx={{position:'absolute', top:0, left:0, width:'100%', height:'100%', overflowY:'auto'}}>

          {calculation !== null ? (
          <Grid container>
            <Grid item xs={12} xl={6}>
                <Stack spacing={2} className="spacing-2">
                  <Calculation calculation={calculation} setCalculation={setCalculation} />
                </Stack>
            </Grid>
          </Grid>
          ) : null }

        </Box>

      </Box>
      
    </Stack>

  )
}

export default Calculator
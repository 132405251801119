import { Button, InputAdornment, Paper, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import '../Prototypes';
import useApi from '../hooks/useApi';

const RoadParameter = () => {
  
  const api = useApi();
  const [roadParameter, setRoadParameter] = useState({
    min_distance: '', 
    min_volume: '', 
    max_volume: '',
    insurance_coverage: '',
    margin: '',
    person_factor: '',
    living_area_factor: ''
  });
  const [isProcessing, setIsProcessing] = useState(false);
  
  useEffect(() => {
    getRoadParameter();
  }, []);

  const getRoadParameter = async() => {
    const data = await api.getRoadParameter();
    if(data) {
      setRoadParameter(data.result);
    }
  }

  const save = async() => {
    setIsProcessing(true);
    await api.updateRoadParameter(roadParameter);
    setIsProcessing(false);
  }
  
  return (
    <Paper className="spacing-2" sx={{height:'100%'}}>
        <Stack spacing={2} alignItems="flex-start">
          <Typography variant="h2">Allgemein</Typography>
          <TextField 
            size="small" fullWidth
            label="Min. Distanz"
            InputProps={{
              endAdornment: <InputAdornment position="end">km</InputAdornment>,
            }}
            value={roadParameter.min_distance}
            onChange={(e) => setRoadParameter({...roadParameter, min_distance: e.target.value.toIntString()})}
          />
          <TextField 
            size="small" fullWidth
            label="Min. Volumen"
            InputProps={{
              endAdornment: <InputAdornment position="end">m³</InputAdornment>,
            }}
            value={roadParameter.min_volume}
            onChange={(e) => setRoadParameter({...roadParameter, min_volume: e.target.value.toIntString()})}
          />
          <TextField 
            size="small" fullWidth
            label="Max. Volumen"
            InputProps={{
              endAdornment: <InputAdornment position="end">m³</InputAdornment>,
            }}
            value={roadParameter.max_volume}
            onChange={(e) => setRoadParameter({...roadParameter, max_volume: e.target.value.toIntString()})}
          />
          <TextField 
            size="small" fullWidth
            label="Versicherungssumme"
            InputProps={{
              endAdornment: <InputAdornment position="end">EUR / kg</InputAdornment>,
            }}
            value={roadParameter.insurance_coverage.toCommaString()}
            onChange={(e) => setRoadParameter({...roadParameter, insurance_coverage: e.target.value.toFloatString()})}
          />
          <TextField 
            size="small" fullWidth
            label="Marge"
            value={roadParameter.margin.toCommaString()}
            onChange={(e) => setRoadParameter({...roadParameter, margin: e.target.value.toFloatString()})}
          />
          <TextField 
            size="small" fullWidth
            label="Personenfaktor"
            value={roadParameter.person_factor.toCommaString()}
            onChange={(e) => setRoadParameter({...roadParameter, person_factor: e.target.value.toFloatString()})}
          />
          <TextField 
            size="small" fullWidth
            label="Wohflächenfaktor"
            value={roadParameter.living_area_factor.toCommaString()}
            onChange={(e) => setRoadParameter({...roadParameter, living_area_factor: e.target.value.toFloatString()})}
          />
          <Stack direction="row" justifyContent="flex-end" sx={{width:'100%'}}>
            <Button variant="contained" disabled={isProcessing || !roadParameter.min_distance || !roadParameter.min_volume || !roadParameter.max_volume || !roadParameter.margin} onClick={save}>Speichern</Button>
          </Stack>
        </Stack>
    </Paper>
  )
}

export default RoadParameter
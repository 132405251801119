String.prototype.toCommaString = function() {
    return this.valueOf().toString().replace('.', ',');
};

Number.prototype.toCommaString = function() {
    return this.valueOf().toString().replace('.', ',');
};

String.prototype.toFloatString = function() {
    const val = this.valueOf();
    const pattern = /([0-9]*)([\,\.]{0,1})([0-9]*)/i;
    const result = val.match(pattern);
    return result[0].replace(',', '.');
}

String.prototype.toIntString = function() {
    const val = this.valueOf();
    const pattern = /([0-9]*)/i;
    const result = val.match(pattern);
    return result[0];
}

String.prototype.toLatLong = function() {
    const val = this.valueOf();
    const pattern = /([-]{0,1})([0-9]*)([\,\.]{0,1})([0-9]*)/i;
    const result = val.match(pattern);
    return result[0].replace(',', '.');
}

Number.prototype.toRefNo = function() {
    const val = this.valueOf();
    return '#' + val.toString().padStart(6, 0);
}

Number.prototype.toCurrency = function(currency) {
    const val = this.valueOf() ? this.valueOf() : 0;
    return number_format(val * currency.rate, 2, ',', '.') + ' ' + currency.code;
}

String.prototype.toPhoneLink = function() {
    return this.valueOf().replaceAll(' ', '');
};


function number_format(number, decimals, dec_point, thousands_sep) {
    var n = !isFinite(+number) ? 0 : +number, 
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        toFixedFix = function (n, prec) {
            // Fix for IE parseFloat(0.55).toFixed(0) = 0;
            var k = Math.pow(10, prec);
            return Math.round(n * k) / k;
        },
        s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}
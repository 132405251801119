import { Button, IconButton, InputAdornment, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useState } from 'react'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import '../Prototypes';
import useApi from '../hooks/useApi';

const Trucks = ({trucks, getTrucks}) => {
  
  const api = useApi();
  const [truck, setTruck] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const add = () => {
    setTruck({
      name: '',
      volume: '',
      weight: '',
      axles: ''
    });
  }

  const edit = (item) => {
    setTruck(item);
  }

  const save = async() => {
    setIsProcessing(true);
    const data = truck.id ? await api.updateTruck(truck) : await api.addTruck(truck);
    if(data) {
      setTruck(null);
      getTrucks();
    }
    setIsProcessing(false);
  }

  const remove = async() => {
    setIsProcessing(true);
    const data = await api.removeTruck(truck.id);
    if(data) {
        setTruck(null);
        getTrucks();
    }
    setIsProcessing(false);
  }

  const abort = () => {
    setTruck(null);
  }

  return (
    <>
      <Paper className="spacing-2" sx={{height:'100%'}}>
          <Stack spacing={2}>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Typography variant="h2">Trucks</Typography>
              <Button variant="outlined" onClick={add}>Hinzufügen</Button>
            </Stack>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow sx={{verticalAlign:'top'}}>
                    <TableCell><Typography variant="caption">Bezeichnung</Typography></TableCell>
                    <TableCell><Typography variant="caption">Volumen<br/>m³</Typography></TableCell>
                    <TableCell><Typography variant="caption">Gewicht<br/>t</Typography></TableCell>
                    <TableCell><Typography variant="caption">Achsen</Typography></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {trucks.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell sx={{whiteSpace:'nowrap'}}>{item.volume.toCommaString()}</TableCell>
                    <TableCell sx={{whiteSpace:'nowrap'}}>{item.weight.toCommaString()}</TableCell>
                    <TableCell>{item.axles}</TableCell>
                    <TableCell sx={{textAlign:'right'}}>
                      <IconButton onClick={() => edit(item)}>
                        <EditOutlinedIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
      </Paper>
      <Modal open={truck !== null} onClose={abort}>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{minHeight:'100%'}}>
          {truck !== null ? (
          <Paper className="spacing-2">
            <Stack spacing={2}>
              <Typography variant="h2">Truck</Typography>
              <TextField
                size="small"
                label="Bezeichnung"
                value={truck.name}
                onChange={(e) => setTruck({...truck, name:e.target.value})}
              />
              <TextField
                size="small"
                label="Volumen"
                InputProps={{
                  endAdornment: <InputAdornment position="start">m³</InputAdornment>,
                }}
                value={truck.volume.toCommaString()}
                onChange={(e) => setTruck({...truck, volume:e.target.value.toFloatString()})}
              />
              <TextField
                size="small"
                label="Gewicht"
                InputProps={{
                  endAdornment: <InputAdornment position="start">t</InputAdornment>,
                }}
                value={truck.weight.toCommaString()}
                onChange={(e) => setTruck({...truck, weight:e.target.value.toFloatString()})}
              />
              <TextField
                size="small"
                label="Achsen"
                value={truck.axles}
                onChange={(e) => setTruck({...truck, axles:e.target.value.toIntString()})}
              />
              <Stack direction="row" spacing={2}>
                {truck.id ? (
                <Button variant="outlined" color="error" onClick={remove} disabled={isProcessing}>Löschen</Button>
                ) : null}
                <Button variant="outlined" onClick={abort}>Abbrechen</Button>
                <Button variant="contained" onClick={save} disabled={isProcessing || !truck.name || !truck.volume || !truck.weight || !truck.axles}>Speichern</Button>
              </Stack>
            </Stack>
          </Paper>
          ): null}
        </Stack>
      </Modal>
    </>

  )
}

export default Trucks